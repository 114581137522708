import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ReduxAction } from "sdk/tools/constants/Redux";

export const audioSlice = createSlice({
  name: "audio",
  initialState: null,
  reducers: ReduxAction,
});

export const audioActions = audioSlice.actions;

export const audioSelector = createSelector(
  (state) => state,
  (state) => state.audio
);
