import { useQueryClient } from "@tanstack/react-query";

function useQueryRemove() {
  const queryClient = useQueryClient();

  const remove = ({ queryKey }) => queryClient.removeQueries({ queryKey: queryKey });

  return remove;
}

export default useQueryRemove;
