import Shimmer from "sdk/components/Loader/Shimmer";
import useInteraction from "sdk/tools/hooks/useInteraction";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function CardLegacy({
  as = "div",
  clickable = true,
  selected = false,
  loading = false,
  disabled = false,
  onClick = null,
  className = null,
  children,
}) {
  const Container = as ?? "div";
  const isClickable = clickable && !disabled && !loading;

  const classNames = useTwClassNames({
    default: true,
    defaultClassName:
      "relative w-full border border-transparent rounded-md bg-fillCardLight dark:bg-fillCard outline-none px-5 py-4",
    isClickable,
    isClickableClassName: "hover:!bg-opacity-80 cursor-pointer focus:border-accentDark",
    selected: selected,
    selectedClassName: "ring-1 ring-inset ring-white",
    loading: loading,
    loadingClassName: "overflow-x-hidden",
    className,
  });

  const interactions = useInteraction({ disabled: !isClickable, callback: onClick });

  return (
    <Container {...classNames} {...interactions} role={isClickable ? "button" : null}>
      {children}
      {loading && <Shimmer />}
    </Container>
  );
}

export default CardLegacy;
