import { storeApple } from "constants/Cdn";
import { externalLink } from "constants/Link";
import Image from "sdk/components/Generic/Image";

function StoreAppleLogo() {
  return (
    <a
      className="animate-fadeIn outline-none focus:ring-2 rounded-[0.4rem] focus:ring-accentDark"
      target="_blank"
      rel="noreferrer"
      href={externalLink.appStore}
    >
      <Image className="h-12" opacity={1} src={storeApple} />
    </a>
  );
}

export default StoreAppleLogo;
