import { BatchDTO, CustomKeyDTO, RightHolderDTO, SongDTO } from "constants/DTO";
import useFormatString from "sdk/tools/hooks/useFormatString";
import { useCallback } from "react";
import { toast } from "react-toastify";

export function useToastError() {
  const formatString = useFormatString();

  const setError = useCallback(
    (error, params) => {
      let errorSet = error?.response?.data?.errors?.length ? error?.response?.data?.errors : null;
      if (!errorSet) return;
      errorSet = errorSet?.map((x) => formatString(x?.errorCode)).slice(0, 3);
      const errorSetPlaceholder = errorSet.join("\n");
      toast(errorSetPlaceholder, {
        toastId: errorSetPlaceholder,
        ...params,
      });
    },
    [formatString]
  );

  return setError;
}

export function useToastErrorMap() {
  const setError = useToastError();

  const setErrorMap = useCallback(
    (error, params) => {
      error?.length && error?.forEach((x) => setError(x, params));
    },
    [setError]
  );

  return setErrorMap;
}

export function useToastErrorGeneric() {
  const formatString = useFormatString();

  const setErrorGeneric = useCallback(
    (params) => {
      const infoPlaceholder = formatString("error.generic");
      toast(infoPlaceholder, {
        toastId: infoPlaceholder,
        ...params,
      });
    },
    [formatString]
  );

  return setErrorGeneric;
}

export function useToastErrorDocument() {
  const formatString = useFormatString();

  const setErrorDocument = useCallback(
    (params) => {
      const infoPlaceholder = formatString("document_error");
      toast(infoPlaceholder, {
        toastId: infoPlaceholder,
        ...params,
      });
    },
    [formatString]
  );

  return setErrorDocument;
}

export function useToastInfo() {
  const formatString = useFormatString();

  const setInfo = useCallback(
    (info, params) => {
      const infoPlaceholder = formatString(info);
      toast(infoPlaceholder, {
        toastId: infoPlaceholder,
        ...params,
      });
    },
    [formatString]
  );

  return setInfo;
}

export function useToastErrorBatch() {
  const formatString = useFormatString();

  const setErrorBatch = useCallback(
    (error, batch) => {
      let errorSet = error?.response?.data?.errors?.length ? error?.response?.data?.errors : null;
      if (!errorSet) return;
      errorSet = errorSet
        ?.map((x) => {
          let params = {};
          if (x?.params) {
            const songIndex = x?.params?.songIndex - 1;
            const rightHolderIndex = x?.params?.songIndex - 1;
            const song = batch?.[BatchDTO.songs]?.[songIndex]?.[SongDTO.name];
            const artist = batch?.[BatchDTO.songs]?.[songIndex]?.[SongDTO.artist];
            const rightsHolder =
              batch?.[BatchDTO.songs]?.[songIndex]?.[CustomKeyDTO.rightHolders]?.[rightHolderIndex]?.[
                RightHolderDTO.fullName
              ];
            params = { song, artist, rightsHolder };
          }
          return formatString(x?.errorCode, params);
        })
        .slice(0, 3);
      const errorSetPlaceholder = errorSet.join("\n");
      toast(errorSetPlaceholder, {
        toastId: errorSetPlaceholder,
      });
    },
    [formatString]
  );

  return setErrorBatch;
}
