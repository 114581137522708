import { useQueryClient } from "@tanstack/react-query";

function useQueryGetData({ queryKey }) {
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData(queryKey);

  return data;
}

export default useQueryGetData;
