import dayjs from "dayjs";
import { useCallback } from "react";

function useDateAfter() {
  const dateAfter = useCallback((date, limitDate) => {
    if (!date || !limitDate) return null;
    return dayjs(date).isAfter(limitDate);
  }, []);

  return dateAfter;
}

export default useDateAfter;
