import IconCircular from "sdk/components/Icon/IconCircular";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function WrapperIconCircular({ size = 22, className = null }) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "cursor-auto pointer-events-none flex w-full items-center justify-center text-primary",
    className: className,
  });
  return (
    <div {...classNames}>
      <div className="flex items-center justify-center rounded-full bg-fillCard p-2">
        <IconCircular thickness={6} size={size} sx={{ color: "inherit", display: "flex" }} />
      </div>
    </div>
  );
}

export default WrapperIconCircular;
