import ButtonLegacy from "sdk/components/Button/ButtonLegacy";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function ButtonLegacyLargeSecondary({
  clickable = true,
  loading = false,
  disabled = false,
  invert = false,
  loaderSize = 20,
  placeholder = null,
  rightIcon = null,
  rightIconProps = null,
  onClick = null,
  className = null,
}) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName:
      "rounded-lg h-[3.125rem] bg-fillDark px-5 py-2 text-base font-bold border border-transparent focus:border-accentDark",
    disabled: disabled,
    disabledClassName: "bg-fillDark text-textDisabled",
    className: className,
  });

  return (
    <ButtonLegacy
      clickable={clickable}
      loading={loading}
      disabled={disabled}
      loaderSize={loaderSize}
      invert={invert}
      placeholder={placeholder}
      rightIcon={rightIcon}
      rightIconProps={rightIconProps}
      onClick={onClick}
      {...classNames}
    />
  );
}

export default ButtonLegacyLargeSecondary;
