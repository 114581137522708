import { AddressDTO, CityDTO, CountryDTO, IpiDTO, ServiceDTO, UserContactDTO, UserIndividualDTO } from "constants/DTO";
import { userContactType, userServiceGroup, userStatus, userTypes } from "constants/User";
import { serviceShortname } from "sdk/constants/Colour";
import useQueryGetData from "sdk/tools/hooks/useQueryGetData";
import useServiceUiOrderSort from "hooks/useServiceUiOrderSort";
import useQueryUser, { userQueryKey } from "services/queries/useQueryUser";

export function useUserAccountGroup() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.accountGroup];
}

export function useUserActiveService() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.services]?.filter((service) => service.active);
}

export function useUserAddress() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.address];
}

export function useUserAddressCountry() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  let address = null;

  if (user?.[UserIndividualDTO.type] === userTypes.individual) address = user?.[UserIndividualDTO.address];
  if (user?.[UserIndividualDTO.type] === userTypes.company) address = user?.[UserIndividualDTO.invoicingAddress];
  return address?.[AddressDTO.country];
}

export function useUserArtistName() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.artistName];
}

export function useUserBirthCityName() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.birthCity]?.[CityDTO.name];
}

export function useUserBirthCityProvince() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.birthCity]?.[CityDTO.province];
}

export function useUserBirthCountryCode() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.birthCountry]?.[CountryDTO.code];
}

export function useUserBirthDate() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.birthDate];
}

export function useUserContacts() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.contacts];
}

export function useUserContactMobile() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.contacts]?.find((x) => x?.[UserContactDTO.type] === userContactType.mobile);
}

export function useUserContactEmail() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.contacts]?.find((x) => x?.[UserContactDTO.type] === userContactType.email);
}

export function useUserDisplayName() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.displayName];
}

export function useUserEconomicActivityCode() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.economicActivityCode];
}

export function useUserEditAddress() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  const type = user?.[UserIndividualDTO.type];
  let address = null;
  if (user?.[UserIndividualDTO.type] === userTypes.individual) address = user?.[UserIndividualDTO.address];
  if (user?.[UserIndividualDTO.type] === userTypes.company) address = user?.[UserIndividualDTO.invoicingAddress];
  return { type, address };
}

export function useUserEditNumber() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  const contact = user?.[UserIndividualDTO.contacts]?.find((x) => x?.[UserContactDTO.type] === userContactType.mobile);
  const id = contact?.[UserContactDTO.id];
  const dialCode = contact?.[UserContactDTO.contact]?.split(" ")?.[0];
  const phoneNumber = contact?.[UserContactDTO.contact]?.split(" ")?.[1];
  return { id, dialCode, phoneNumber };
}

export function useUserEmail() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.userName];
}

export function useUserFirstAvailableDate() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.firstAvailableDate];
}

export function useUserFiscalCode() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.fiscalCode];
}

export function useUserGender() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.gender];
}

export function useUserGuardian() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.guardian];
}

export function useUserInvoicingAddress() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.invoicingAddress];
}

export function useUserHasMonitoringEnabled() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return !!user?.[UserIndividualDTO.services]?.find(
    (service) => service?.[ServiceDTO.shortname] === serviceShortname.rt
  );
}

export function useUserHasInStore() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return !!user?.[UserIndividualDTO.services]?.find(
    (service) => service?.[ServiceDTO.shortname] === serviceShortname.bm
  );
}

export function useUserIsCollectingInStoreActive() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  const inStore = user?.[UserIndividualDTO.services]?.find((x) => x?.[ServiceDTO.shortname] === serviceShortname.bm);
  const isCollectingInStore = user?.[UserIndividualDTO.serviceGroup] === userServiceGroup.all;
  const isInStoreActive = !!inStore?.active;
  return isCollectingInStore && isInStoreActive;
}

export function useUserIsReadOnly() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.userStatus]?.toLowerCase() !== userStatus.active;
}

export function useUserIsValid() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return (
    user?.[UserIndividualDTO.userStatus]?.toLowerCase() !== userStatus.frozen &&
    user?.[UserIndividualDTO.userStatus]?.toLowerCase() !== userStatus.validating
  );
}

export function useUserIsAudioEnabled() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  const hasMonitoringEnabled = useUserHasMonitoringEnabled();
  const hasInStore = useUserHasInStore();

  if (!user) return null;
  return hasMonitoringEnabled || hasInStore;
}

export function useUserIsCompany() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.type] === userTypes.company;
}

export function useUserIsLiveEventsEnabled() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  const services = useUserService();

  if (!user) return null;
  return !!services?.find((x) => x?.[ServiceDTO.shortname] === serviceShortname.le);
}

export function useUserFirstName() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.firstName];
}

export function useUserLastName() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.lastName];
}

export function useUserMobileNumber() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.contacts]?.find(
    (contact) => contact?.[UserContactDTO.type] === userContactType.mobile
  )?.[UserContactDTO.contact];
}

export function useUserPaymentMethod() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.paymentMethod];
}

export function useUserRightType() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.rightTypes];
}

export function useUserStatus() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.userStatus]?.toLowerCase();
}

export function useUserTaxProfile() {
  const { data: user } = useQueryUser(false);

  if (!user) return null;
  return user?.[UserIndividualDTO.taxProfile];
}

export function useUserType() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.type];
}

export function useUserVAT() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.vatNumber];
}

export function useUserID() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.id];
}

export function useUserIPI() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.ipi]?.[IpiDTO.ipi];
}

function useUserService() {
  const user = useQueryGetData({ queryKey: userQueryKey });

  if (!user) return null;
  return user?.[UserIndividualDTO.services];
}

export function useUserServiceUiOrder() {
  const userServices = useUserService();
  const orderedServices = useServiceUiOrderSort(userServices);
  return orderedServices;
}
