import { useQuery } from "@tanstack/react-query";
import { linkEnvironment } from "constants/Link";
import { settingsGet } from "sdk/services/endpoints";

export const settingUrlQueryKey = ["global", "url"];

function useQueryUrl(enabled = true) {
  const queryFn = async () => {
    const urls = await settingsGet({ category: linkEnvironment });
    return urls;
  };

  const { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess } = useQuery({
    enabled,
    retry: 2,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: settingUrlQueryKey,
    queryFn,
  });

  return { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess };
}

export default useQueryUrl;
