import Hidden from "sdk/tools/components/Hidden";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function ContainerColumn({ as = "div", hidden = false, className = null, children }) {
  const Container = as ?? "div";

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "grid grid-cols-1",
    className,
  });

  return (
    <Hidden hidden={hidden}>
      <Container {...classNames}>{children}</Container>
    </Hidden>
  );
}

export default ContainerColumn;
