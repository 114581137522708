import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SettingDTO } from "constants/DTO";
import { path } from "constants/Route";
import { userSettingKey } from "constants/Setting";
import { usersSettingsPost } from "sdk/services/endpoints";
import { useToastError, useToastInfo } from "hooks/useToast";
import { preferenceQueryKey } from "services/queries/useQueryPreference";
import { useLocation } from "react-router-dom";

const preferenceDiscreetModeMutationKey = ["protected", "preference", "discreetMode"];

function useMutationPreferenceDiscreetMode() {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  const setError = useToastError();
  const setInfo = useToastInfo();

  const mutationFn = async (value) => {
    const payload = {
      [SettingDTO.key]: userSettingKey.discreetMode,
      [SettingDTO.type]: "boolean",
      [SettingDTO.value]: value,
    };
    if (pathname.split("/")[1] === path.PROTECTED) await usersSettingsPost(payload);
    return payload;
  };

  const onSuccess = (payload) => {
    const key = payload?.[SettingDTO.key];
    const value = payload?.[SettingDTO.value];
    if (value) setInfo("discreet_mode_on");
    if (!value) setInfo("discreet_mode_off");
    queryClient.setQueryData(preferenceQueryKey, (preference) => {
      if (preference) return { ...preference, [key]: value };
      return { [key]: value };
    });
  };

  const { mutate, data, error, isPending, isError, isSuccess } = useMutation({
    mutationKey: preferenceDiscreetModeMutationKey,
    mutationFn: mutationFn,
    onError: setError,
    onSuccess: onSuccess,
  });

  return { mutate, data, error, isPending, isError, isSuccess };
}

export default useMutationPreferenceDiscreetMode;
