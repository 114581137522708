import { userSettingKey } from "constants/Setting";
import ButtonIcon from "sdk/components/Button/ButtonIcon";
import { Icons } from "sdk/components/Icon/Icons";
import useMutationPreferenceDiscreetMode from "services/mutations/useMutationPreferenceDiscreetMode";
import useQueryPreference from "services/queries/useQueryPreference";

function DiscreetModeButton() {
  const { data: preference } = useQueryPreference(false);
  const { mutate, isPending } = useMutationPreferenceDiscreetMode();

  const discreetMode = preference?.[userSettingKey.discreetMode];

  const onClickHandler = (e) => {
    e.stopPropagation();
    mutate(!discreetMode);
  };

  const icon = discreetMode ? Icons.visibilityOff : Icons.removeRedEye;

  return <ButtonIcon className="text-textMedium w-6 h-3" loading={isPending} icon={icon} onClick={onClickHandler} />;
}

export default DiscreetModeButton;
