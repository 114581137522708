import { combineReducers, createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { sortByDirection } from "constants/Library";
import { path } from "constants/Route";
import { royaltySortBy } from "constants/Royalty";
import { ReduxAction } from "sdk/tools/constants/Redux";

const filterYearSlice = createSlice({
  name: "royalty/filterYear",
  initialState: new Date().getFullYear(),
  reducers: ReduxAction,
});

const filterTabSlice = createSlice({
  name: "royalty/filterTab",
  initialState: path.OVERVIEW,
  reducers: ReduxAction,
});

const groupBySlice = createSlice({
  name: "royalty/detail/groupBy",
  initialState: null,
  reducers: ReduxAction,
});

const sortBySlice = createSlice({
  name: "royalty/detail/sortBy",
  initialState: `${sortByDirection.descendant}${royaltySortBy.royalties}`,
  reducers: ReduxAction,
});

const filterSubTypeSlice = createSlice({
  name: "royalty/detail/filterSubType",
  initialState: null,
  reducers: ReduxAction,
});

const filterUsageDateSlice = createSlice({
  name: "royalty/detail/filterUsageDate",
  initialState: null,
  reducers: ReduxAction,
});

const filterLicenseSlice = createSlice({
  name: "royalty/detail/filterLicense",
  initialState: null,
  reducers: ReduxAction,
});

const filterCustomerSlice = createSlice({
  name: "royalty/detail/filterCustomer",
  initialState: null,
  reducers: ReduxAction,
});

const filterSongSlice = createSlice({
  name: "royalty/detail/filterSong",
  initialState: null,
  reducers: ReduxAction,
});

const filterCountrySlice = createSlice({
  name: "royalty/detail/filterCountry",
  initialState: null,
  reducers: ReduxAction,
});

export const filterYearActions = filterYearSlice.actions;
export const filterTabActions = filterTabSlice.actions;
export const groupByActions = groupBySlice.actions;
export const sortByActions = sortBySlice.actions;
export const filterSubTypeActions = filterSubTypeSlice.actions;
export const filterUsageDateActions = filterUsageDateSlice.actions;
export const filterLicenseActions = filterLicenseSlice.actions;
export const filterCustomerActions = filterCustomerSlice.actions;
export const filterSongActions = filterSongSlice.actions;
export const filterCountryActions = filterCountrySlice.actions;

export const cleanRoyaltyDetailReducer = createAction("clean/royalty/detail");

export const groupBySelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.groupBy
);

export const sortBySelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.sortBy
);

export const filterYearSelector = createSelector(
  (state) => state,
  (state) => state.royalty.filterYear
);

export const filterTabSelector = createSelector(
  (state) => state,
  (state) => state.royalty.filterTab
);

export const filterSubTypeSelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.filterSubType
);

export const filterUsageDateSelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.filterUsageDate
);

export const filterLicenseSelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.filterLicense
);

export const filterCustomerSelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.filterCustomer
);

export const filterSongSelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.filterSong
);

export const filterCountrySelector = createSelector(
  (state) => state,
  (state) => state.royalty.detail.filterCountry
);

const resettableDetailReducer = combineReducers({
  groupBy: groupBySlice.reducer,
  sortBy: sortBySlice.reducer,
  filterSubType: filterSubTypeSlice.reducer,
  filterUsageDate: filterUsageDateSlice.reducer,
  filterLicense: filterLicenseSlice.reducer,
  filterCustomer: filterCustomerSlice.reducer,
  filterSong: filterSongSlice.reducer,
  filterCountry: filterCountrySlice.reducer,
});

const detailReducer = (state, action) => {
  if (cleanRoyaltyDetailReducer.type === action.type) return resettableDetailReducer(undefined, action);
  return resettableDetailReducer(state, action);
};

const royaltyReducer = combineReducers({
  filterYear: filterYearSlice.reducer,
  filterTab: filterTabSlice.reducer,
  detail: detailReducer,
});

export default royaltyReducer;
