import { useCallback } from "react";

function useFlatObject() {
  const flatObject = useCallback((obj, parent, res = {}) => {
    if (typeof obj !== "object") return null;

    for (let key in obj) {
      let propName = parent ? parent + "." + key : key;
      if (typeof obj[key] == "object") {
        flatObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }, []);

  return flatObject;
}

export default useFlatObject;
