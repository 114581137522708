import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sdk/styles/toastify.css";

function ToastifyProvider({ progressClassName, bodyClassName }) {
  return (
    <ToastContainer
      progressClassName={progressClassName}
      bodyClassName={bodyClassName}
      position="bottom-center"
      hideProgressBar={false}
      closeButton={false}
      closeOnClick={true}
      newestOnTop={true}
      autoClose={3600}
      draggablePercent={50}
      limit={2}
    />
  );
}

export default ToastifyProvider;
