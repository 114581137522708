import EventCode from "sdk/tools/constants/EventCode";

function useInteraction({ disabled = false, stopPropagation = false, preventDefault = false, callback }) {
  const tabIndex = disabled ? -1 : 0;

  const onClickHandler = (event) => {
    if (preventDefault) event.preventDefault();
    if (stopPropagation) event?.stopPropagation();
    callback && callback(event);
  };

  const onMouseUp = (event) => {
    if (disabled) return;
    document.activeElement?.blur();
    onClickHandler(event);
  };

  const onKeyUp = (event) => {
    if (disabled) return;
    if (event?.code === EventCode.enter && event?.type === "keyup") onClickHandler(event);
  };

  return { onMouseUp, onKeyUp, tabIndex };
}

export default useInteraction;
