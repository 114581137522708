import { Navigate } from "react-router-dom";

function Guard({ guardBypass = false, guardIf = false, shouldHide = false, navigateTo = null, children }) {
  if (guardBypass) return children;
  if (guardIf && shouldHide) return null;
  if (guardIf && navigateTo) return <Navigate to={navigateTo} />;
  if (!guardIf) return children;
}

export default Guard;
