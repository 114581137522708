import { combineReducers, createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ReduxAction } from "sdk/tools/constants/Redux";

const filterStatusSlice = createSlice({
  name: "detail/blockhain/filterStatus",
  initialState: 0,
  reducers: ReduxAction,
});

const filterSoundreefSlice = createSlice({
  name: "detail/blockhain/filterSoundreef",
  initialState: 0,
  reducers: ReduxAction,
});

const filterSearchSlice = createSlice({
  name: "detail/version/filterSearch",
  initialState: null,
  reducers: ReduxAction,
});

const filterSearchTypeSlice = createSlice({
  name: "detail/version/filterSearchType",
  initialState: 0,
  reducers: ReduxAction,
});

const filterYear = createSlice({
  name: "detail/version/filterYear",
  initialState: null,
  reducers: ReduxAction,
});

const filterISRCSlice = createSlice({
  name: "detail/version/filterISRC",
  initialState: "",
  reducers: ReduxAction,
});

const filterMetadataSlice = createSlice({
  name: "detail/version/filterMetadata",
  initialState: 0,
  reducers: ReduxAction,
});

export const filterStatusActions = filterStatusSlice.actions;
export const filterSoundreefActions = filterSoundreefSlice.actions;
export const filterSearchActions = filterSearchSlice.actions;
export const filterSearchTypeActions = filterSearchTypeSlice.actions;
export const filterYearActions = filterYear.actions;
export const filterISRCActions = filterISRCSlice.actions;
export const filterMetadataActions = filterMetadataSlice.actions;

export const filterStatusSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterStatus
);

export const filterSoundreefSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterSoundreef
);

export const filterSearchSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterSearch
);

export const filterSearchTypeSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterSearchType
);

export const filterYearSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterYear
);

export const filterISRCSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterISRC
);

export const filterMetadataSelector = createSelector(
  (state) => state,
  (state) => state.detail.filterMetadata
);

export const cleanDetailReducer = createAction("clean/detail");

const resetDetailReducer = combineReducers({
  filterStatus: filterStatusSlice.reducer,
  filterSoundreef: filterSoundreefSlice.reducer,
  filterSearch: filterSearchSlice.reducer,
  filterSearchType: filterSearchTypeSlice.reducer,
  filterYear: filterYear.reducer,
  filterISRC: filterISRCSlice.reducer,
  filterMetadata: filterMetadataSlice.reducer,
});

const detailReducer = (state, action) => {
  if (cleanDetailReducer.type === action.type) return resetDetailReducer(undefined, action);
  return resetDetailReducer(state, action);
};

export default detailReducer;
