import { soundreefIconAppBanner } from "constants/Cdn";
import { externalLink } from "constants/Link";
import ButtonIcon from "sdk/components/Button/ButtonIcon";
import Href from "sdk/components/Generic/Href";
import { Icons } from "sdk/components/Icon/Icons";
import Image from "sdk/components/Generic/Image";
import Paragraph from "sdk/components/Paragraph/Paragraph";
import useViewDesktop from "sdk/hooks/useViewDesktop";
import useFormatString from "sdk/tools/hooks/useFormatString";
import { useState } from "react";
import { isIOS, isMobile } from "react-device-detect";

function BannerAppMobile() {
  const isDesktop = useViewDesktop();
  const formatString = useFormatString();

  const [isVisible, setIsVisible] = useState(isMobile);

  const hrefHandler = (() => {
    if (isIOS) return externalLink.appStore;
    return externalLink.appGooglePlayStore;
  })();

  const onCloseHandler = () => setIsVisible(false);

  if (!isVisible || isDesktop) return;

  return (
    <div className="animate-fadeIn z-max gap-2 px-smallPadding items-center flex bg-fillCard h-[5rem] w-full">
      <ButtonIcon
        icon={Icons.close}
        className="flex justify-center items-center text-textMedium"
        onClick={onCloseHandler}
      />
      <div className="flex-none w-14 h-14">
        <Image src={soundreefIconAppBanner} />
      </div>
      <div className="flex flex-col gap-1 min-w-0">
        <Paragraph className="text-base font-bold leading-lg text-textWhite" placeholder={formatString("soundreef")} />
        <Paragraph
          className="text-xs font-medium text-textWhite ellipsis"
          placeholder={formatString("app_download_now")}
        />
      </div>
      <Href
        href={hrefHandler}
        placeholder={formatString("app_install")}
        className="text-base font-bold text-accentPrimary ml-auto"
      />
    </div>
  );
}

export default BannerAppMobile;
