// Personal DTO used in the app
export const CustomKeyDTO = {
  dialCode: "dialCode",
  key: "key",
  label: "label",
  page: "page",
  pageSize: "pageSize",
  percentage: "percentage",
  phoneNumber: "phoneNumber",
  privacyAccepted: "privacyAccepted",
  recordingId: "recordingId",
  rightHolders: "rightHolders",
  serviceColour: "serviceColour",
  token: "token",
  topology: "topology",
  uniqueId: "uniqueId",
  value: "value",
};

// Soundeef DTO
export const AccountGroupUserDTO = {
  userId: "userId",
  displayName: "displayName",
  userType: "userType",
  role: "role",
};
export const AccruedDTO = {
  amount: "amount",
  currency: "currency",
  items: "items",
};

export const AccruedItemDTO = {
  service: "service",
  amount: "amount",
};

export const AdvanceSummaryDTO = {
  amount: "amount",
  currency: "currency",
  lastUpdate: "lastUpdate",
  recoupedAmount: "recoupedAmount",
  recoupedAmountSinceLastUpdate: "recoupedAmountSinceLastUpdate",
  advances: "advances",
};

export const AdvanceDTO = {
  amount: "amount",
  currency: "currency",
  date: "date",
  recoupedAmount: "recoupedAmount",
  recoupedAmountSinceLastUpdate: "recoupedAmountSinceLastUpdate",
  status: "status",
};

export const AddressDTO = {
  address: "address",
  postCode: "postCode",
  city: "city",
  country: "country",
};

export const BalanceDTO = {
  amount: "amount",
  advanceRecoupAmount: "advanceRecoupAmount",
  privateCopyingAdvanceRecoupAmount: "privateCopyingAdvanceRecoupAmount",
  royaltyAdvanceRecoupAmount: "royaltyAdvanceRecoupAmount",
  rounding: "rounding",
  withholdingAmount: "withholdingAmount",
  withholdingPercentage: "withholdingPercentage",
  vatPercentage: "vatPercentage",
  vatAmount: "vatAmount",
  vatExempt: "vatExempt",
  netAmount: "netAmount",
  invoice: "invoice",
  currency: "currency",
  documents: "documents",
  earningsUntil: "earningsUntil",
  checkoutRequired: "checkoutRequired",
  checkout: "checkout",
};

export const BatchDTO = {
  id: "id",
  createdOn: "createdOn",
  updatedOn: "updatedOn",
  status: "status",
  songs: "songs",
  documents: "documents",
  excludeInstore: "excludeInstore",
};

export const BatchDigestDTO = {
  id: "id",
  status: "status",
  createdOn: "createdOn",
  updatedOn: "updatedOn",
  rightsHoldersNames: "rightsHoldersNames",
  songsTitles: "songsTitles",
  artists: "artists",
};

export const BroadcastChannelMinimalDTO = {
  id: "id",
  name: "name",
};

export const BroadcasterMinimalDTO = {
  id: "id",
  name: "name",
};

export const CheckoutDTO = {
  requestDate: "requestDate",
  status: "status",
  statusReason: "statusReason",
};

export const CertificationDTO = {
  id: "id",
  songId: "songId",
  fileHash: "fileHash",
  fileName: "fileName",
  proofFilename: "proofFilename",
  certificateFilename: "certificateFilename",
  blockchainBlock: "blockchainBlock",
  subjectType: "subjectType",
  subject: "subject",
  certificationDate: "certificationDate",
  status: "status",
};

export const CityDTO = {
  id: "id",
  name: "name",
  postCode: "postCode",
  province: "province",
  cadastralCode: "cadastralCode",
};

export const CollectingDTO = {
  id: "id",
  name: "name",
};

export const CountryDTO = {
  id: "id",
  name: "name",
  code: "code",
  codeAlpha3: "codeAlpha3",
  dialCode: "dialCode",
  placeholder: "placeholder",
  cadastralCode: "cadastralCode",
};

export const CountryMinimalDTO = {
  code: "code",
  name: "name",
};

export const CustomerMinimalDTO = {
  id: "id",
  name: "name",
  type: "type",
  mediaTypes: "mediaTypes",
};

export const DetectionDTO = {
  mediaType: "mediaType",
  country: "country",
  song: "song",
  broadcaster: "broadcaster",
  broadcastChannel: "broadcastChannel",
  usage: "usage",
  plays: "plays",
};

export const DetectionSearchDTO = {
  mediaType: "mediaType",
  country: "country",
  usage: "usage",
  song: "song",
  broadcastChannel: "broadcastChannel",
  groupBy: "groupBy",
  sortBy: "sortBy",
};

export const DocumentDTO = {
  id: "id",
  name: "name",
  fileName: "fileName",
  date: "date",
  signatureDate: "signatureDate",
  expirationDate: "expirationDate",
  userSignatureDate: "userSignatureDate",
  signaturePostponable: "signaturePostponable",
  status: "status",
  context: "context",
  songsTitles: "songsTitles",
  signersNames: "signersNames",
  type: "type",
  template: "template",
};

export const DocumentTemplateDTO = {
  id: "id",
  name: "name",
  lastUpdate: "lastUpdate",
};

export const EconomicActivityCodeDTO = {
  code: "code",
  placeholder: "placeholder",
};

export const GenreDTO = {
  id: "id",
  name: "name",
  placeholder: "placeholder",
};

export const GuardianDTO = {
  firstName: "firstName",
  lastName: "lastName",
  contacts: "contacts",
};

export const IpiDTO = {
  ipi: "ipi",
  ipiType: "ipiType",
};

export const LicenseMinimalDTO = {
  id: "id",
  name: "name",
  date: "date",
  type: "type",
};

export const PaymentMethodDTO = {
  type: "type",
  address: "address",
  bankCode: "bankCode",
  routingNumber: "routingNumber",
  bankAccountNumber: "bankAccountNumber",
};

export const PaymentMethodTypeDTO = {
  type: "type",
  description: "description",
  descriptionPlaceholder: "descriptionPlaceholder",
  paymentThreshold: "paymentThreshold",
  isDefault: "isDefault",
};

export const PaymentDTO = {
  id: "id",
  type: "type",
  date: "date",
  amount: "amount",
  advanceRecoupAmount: "advanceRecoupAmount",
  privateCopyingAdvanceRecoupAmount: "privateCopyingAdvanceRecoupAmount",
  royaltyAdvanceRecoupAmount: "royaltyAdvanceRecoupAmount",
  rounding: "rounding",
  withholdingAmount: "withholdingAmount",
  vatFreeAmount: "vatFreeAmount",
  vatAmount: "vatAmount",
  netAmount: "netAmount",
  vatPercentage: "vatPercentage",
  withholdingPercentage: "withholdingPercentage",
  currency: "currency",
  documents: "documents",
};

export const PaydayDTO = {
  id: "id",
  date: "date",
  active: "active",
  status: "status",
  descriptionPlaceholder: "descriptionPlaceholder",
  details: "details",
  accrued: "accrued",
};

export const PaydayDetailDTO = {
  service: "service",
  category: "category",
  usage: "usage",
};

export const PaydayCategoryDTO = {
  name: "name",
  placeholder: "placeholder",
};

export const PaydayUsageDTO = {
  name: "name",
  placeholder: "placeholder",
  month: "month",
  quarter: "quarter",
  semester: "semester",
  year: "year",
};

export const PerformanceDTO = {
  payday: "payday",
  topSongs: "topSongs",
  topUsage: "topUsage",
  topTerritories: "topTerritories",
};

export const RecordingDTO = {
  id: "id",
  artist: "artist",
  name: "name",
  version: "version",
  mainRecording: "mainRecording",
  year: "year",
  isrc: "isrc",
  audio: "audio",
};

export const RightTypeDTO = {
  id: "id",
  name: "name",
  shortname: "shortname",
  namePlaceholder: "namePlaceholder",
};

export const RightHolderDTO = {
  id: "id",
  userId: "userId",
  fullName: "fullName",
  firstName: "firstName",
  lastName: "lastName",
  ipi: "ipi",
  type: "type",
  rights: "rights",
};

export const RightHolderRightDTO = {
  type: "type",
  name: "name",
  shortname: "shortname",
  namePlaceholder: "namePlaceholder",
  percentage: "percentage",
  role: "role",
};

export const RightHolderRoleDTO = {
  id: "id",
  name: "name",
  shortname: "shortname",
  namePlaceholder: "namePlaceholder",
  rightHolderType: "rightHolderType",
};

export const RightManagementDTO = {
  id: "id",
  placeholder: "placeholder",
};

export const RoyaltyDTO = {
  service: "service",
  serviceSubtype: "serviceSubtype",
  territory: "territory",
  usage: "usage",
  payment: "payment",
  song: "song",
  customer: "customer",
  license: "license",
  accreuedMonthly: "accreuedMonthly",
  amount: "amount",
  currency: "currency",
  transactionType: "transactionType",
};

export const RoyaltiesFacetsDTO = {
  groupCriterias: "groupCriterias",
  sortCriterias: "sortCriterias",
  servicesShortnames: "servicesShortnames",
  serviceSubtypesShortnames: "serviceSubtypesShortnames",
  usageDates: "usageDates",
  territories: "territories",
  customers: "customers",
  licenses: "licenses",
};

export const RoyaltyDigestDTO = {
  amount: "amount",
  detail: "detail",
};

export const RoyaltyDigestDetailDTO = {
  amount: "amount",
  shortname: "shortname",
};

export const RoyaltyDigestQuarterDTO = {
  year: "year",
  quarter: "quarter",
  usageQuarters: "usageQuarters",
  amount: "amount",
};

export const RoyaltyDigestLicenseLiveEventDTO = {
  id: "id",
  liveEventDate: "liveEventDate",
  liveEventName: "liveEventName",
  royalties: "royalties",
};

export const RoyaltyDigestLicenseInStoreDTO = {
  name: "name",
  royalties: "royalties",
};

export const RoyaltyDigestLicenseDspDTO = {
  id: "id",
  dspName: "dspName",
  royalties: "royalties",
};

export const RoyaltyDigestLicenseLabelDTO = {
  id: "id",
  labelName: "labelName",
  royalties: "royalties",
};

export const RoyaltyDigestTagDTO = {
  id: "id",
  tag: "tag",
  description: "description",
  amount: "amount",
};

export const RoyaltyDigestLicenseBroadCasterDTO = {
  id: "id",
  broadcasterName: "broadcasterName",
  mediaTypes: "mediaTypes",
  royalties: "royalties",
};

export const RoyaltyDigestCountryDTO = {
  territoryCode: "territoryCode",
  territoryName: "territoryName",
  royalties: "royalties",
};

export const RoyaltyDigestSongDTO = {
  id: "id",
  songName: "songName",
  royalties: "royalties",
};

export const RoyaltiesSearchDTO = {
  service: "service",
  serviceSubtype: "serviceSubtype",
  territory: "territory",
  usage: "usage",
  payment: "payment",
  song: "song",
  license: "license",
  customer: "customer",
  groupBy: "groupBy",
  sortBy: "sortBy",
};

export const RoyaltiesPaymentDTO = {
  year: "year",
  semester: "semester",
  quarter: "quarter",
  interval: "interval",
};

export const ServiceDTO = {
  id: "id",
  shortname: "shortname",
  namePlaceholder: "namePlaceholder",
  shortnamePlaceholder: "shortnamePlaceholder",
  status: "status",
  subtypes: "subtypes",
  uiOrder: "uiOrder",
};

export const SettingDTO = {
  key: "key",
  value: "value",
  type: "type",
};

export const SongFileDTO = {
  id: "id",
  filename: "filename",
  originalFilename: "originalFilename",
  ready: "ready",
  format: "format",
  size: "size",
};

export const SongDTO = {
  id: "id",
  name: "name",
  artist: "artist",
  genre: "genre",
  batch: "batch",
  certification: "certification",
  activationDate: "activationDate",
  metadata: "metadata",
  tags: "tags",
  work: "work",
  rightsManagement: "rightsManagement",
  services: "services",
  recordings: "recordings",
};

export const SongMinimalDTO = {
  id: "id",
  name: "name",
  artist: "artist",
};

export const TagDTO = {
  id: "id",
  tag: "tag",
  description: "description",
  context: "context",
};

export const TaxProfileDTO = {
  vatPercentage: "vatPercentage",
  withholdingPercentage: "withholdingPercentage",
  taxRegime: "taxRegime",
  documentation: "documentation",
  documentationStatus: "documentationStatus",
  documentationStatusReason: "documentationStatusReason",
};

export const TaxRegimeDTO = {
  shortname: "shortname",
  placeholder: "placeholder",
};

export const TerritoryMinimalDTO = {
  id: "id",
  name: "name",
  code: "code",
  placeholder: "placeholder",
};

export const TopSongDTO = {
  song: "song",
  amount: "amount",
  currency: "currency",
};

export const TopTerritoryDTO = {
  territory: "territory",
  amount: "amount",
  currency: "currency",
};

export const UserContactDTO = {
  id: "id",
  type: "type",
  contact: "contact",
  confirmed: "confirmed",
};

export const UsageDTO = {
  year: "year",
  month: "month",
  from: "from",
  to: "to",
  interval: "interval",
  date: "date",
  duration: "duration",
  seconds: "seconds",
  show: "show",
  episode: "episode",
};

export const UsageDetailRoyaltiesDTO = {
  radioChannelName: "radioChannelName",
  storeName: "storeName",
  channelName: "channelName",
  showName: "showName",
  productName: "productName",
  productIssueDate: "productIssueDate",
  location: "location",
  eventInfo: "eventInfo",
  durationSeconds: "durationSeconds",
  extraInfo: "extraInfo",
};

export const UsageRoyaltiesDTO = {
  year: "year",
  quarter: "quarter",
  month: "month",
  date: "date",
  time: "time",
  count: "count",
  type: "type",
  details: "details",
  aggregations: "aggregations",
};

export const UsageNotificationDTO = {
  user: "user",
  creationDate: "creationDate",
  usageNotificationType: "usageNotificationType",
  usage: "usage",
  venue: "venue",
  customer: "customer",
};

export const UsageNotificationUsageDTO = {
  name: "name",
  startDate: "startDate",
  endDate: "endDate",
  ticketing: "ticketing",
  type: "type",
  capacity: "capacity",
};

export const UsageNotificationVenueDTO = {
  name: "name",
  city: "city",
  address: "address",
  postCode: "postCode",
  country: "country",
};

export const UsageNotificationCustomerDTO = {
  displayName: "displayName",
  firstName: "firstName",
  lastName: "lastName",
  company: "company",
  email: "email",
  phone: "phone",
  certifiedEmail: "certifiedEmail",
};

export const UsageTypeDTO = {
  id: "id",
  shortname: "shortname",
  name: "name",
  family: "family",
  placeholder: "placeholder",
};

export const UsageNotificationTypeDTO = {
  id: "id",
  shortname: "shortname",
  name: "name",
  description: "description",
  placeholder: "placeholder",
};

export const UserIndividualDTO = {
  id: "id",
  userName: "userName",
  userStatus: "userStatus",
  accountGroup: "accountGroup",
  serviceGroup: "serviceGroup",
  type: "type",
  firstName: "firstName",
  lastName: "lastName",
  displayName: "displayName",
  artistName: "artistName",
  companyName: "companyName",
  ipi: "ipi",
  companyStartDate: "companyStartDate",
  birthDate: "birthDate",
  birthCity: "birthCity",
  birthCountry: "birthCountry",
  gender: "gender",
  guardian: "guardian",
  fiscalCode: "fiscalCode",
  vatNumber: "vatNumber",
  address: "address",
  invoicingAddress: "invoicingAddress",
  paymentMethod: "paymentMethod",
  rightTypes: "rightTypes",
  taxProfile: "taxProfile",
  economicActivityCode: "economicActivityCode",
  services: "services",
  contacts: "contacts",
  firstAvailableDate: "firstAvailableDate",
  password: "password",
};

export const WorkDTO = {
  id: "id",
  iswc: "iswc",
};
