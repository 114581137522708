import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SettingDTO } from "constants/DTO";
import { path } from "constants/Route";
import { userSettingKey } from "constants/Setting";
import { usersSettingsPost } from "sdk/services/endpoints";
import { LocaleContext } from "sdk/tools/providers/LocaleProvider";
import { useToastErrorGeneric } from "hooks/useToast";
import { preferenceQueryKey } from "services/queries/useQueryPreference";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

const preferenceLocaleMutationKey = ["protected", "preference", "locale"];

function useMutationPreferenceLocale() {
  const { setLocale, setBrowserLocale } = useContext(LocaleContext);
  const { pathname } = useLocation();

  const queryClient = useQueryClient();
  const setErrorGeneric = useToastErrorGeneric();

  const mutationFn = async (language) => {
    const payload = {
      [SettingDTO.key]: userSettingKey.language,
      [SettingDTO.value]: language ?? null,
      [SettingDTO.type]: "locale",
    };
    if (pathname.split("/")[1] === path.PROTECTED) await usersSettingsPost(payload);
    return payload;
  };

  const onSuccess = (payload) => {
    const key = payload?.[SettingDTO.key];
    const value = payload?.[SettingDTO.value];
    queryClient.setQueryData(preferenceQueryKey, (preference) => {
      if (preference) return { ...preference, [key]: value };
      return { [key]: value };
    });
  };

  const onChange = (language) => {
    if (language) setLocale(language);
    if (!language) setBrowserLocale();
    if (pathname.split("/")[1] === path.PROTECTED) mutate(language);
  };

  const { mutate, mutateAsync, data, error, isPending, isError, isSuccess } = useMutation({
    throwOnError: false,
    mutationKey: preferenceLocaleMutationKey,
    mutationFn: mutationFn,
    onError: setErrorGeneric,
    onSuccess: onSuccess,
  });

  return { mutate, mutateAsync, data, error, isPending, isError, isSuccess, onChange };
}

export default useMutationPreferenceLocale;
