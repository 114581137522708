import { useQuery } from "@tanstack/react-query";
import { tokenRefreshPost } from "sdk/services/endpoints";
import useLocalStorageToken from "hooks/useLocalStorageToken";

export const authTokenQueryKey = ["protected", "auth", "token"];

function useQueryAuthToken() {
  const { getToken, setToken, getTokenIsExpired, getTokenHasToBeRefreshed } = useLocalStorageToken();

  const queryFn = async () => {
    const token = getToken();
    const tokenIsExpired = await getTokenIsExpired();
    if (!token || tokenIsExpired) throw Error;
    const tokenhasToBeRefreshed = await getTokenHasToBeRefreshed();
    if (tokenhasToBeRefreshed) {
      const newToken = await tokenRefreshPost();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  const { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess } = useQuery({
    retry: 0,
    queryKey: authTokenQueryKey,
    queryFn,
  });

  return { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess };
}

export default useQueryAuthToken;
