export const userStatus = {
  active: "active",
  frozen: "frozen",
  validating: "validating",
  inactive: "inactive",
};

export const userMandateStatus = {
  active: "ACTIVE",
  interminating: "INTERMINATING",
  terminated: "TERMINATED",
};

export const userMandateWithdrawal = {
  mandate_opt_out: "MANDATE_OPT_OUT",
  mandate_withdrawal: "MANDATE_WITHDRAWAL",
};

export const userServiceGroup = {
  all: "all",
  collecting: "collecting",
  instore: "instore",
};

export const userGender = {
  male: "m",
  female: "f",
};

export const userRole = {
  individual: "author",
  company: "publisher",
};

export const userAccountGroupRole = {
  member: "member",
  manager: "manager",
};

export const userTypes = {
  company: "company",
  individual: "individual",
};

export const userPaymentMethodType = {
  bank_transfer: "bank_transfer",
  paypal: "paypal",
};

export const userContactType = {
  email: "email",
  mobile: "mobile",
};
