const { useLayoutEffect } = require("react");

function useResize(callback) {
  useLayoutEffect(() => {
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  });
}

export default useResize;
