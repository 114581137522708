import InputLegacyCard from "sdk/components/Input/InputLegacyCard";
import WrapperField from "sdk/components/Wrapper/WrapperField";
import useFormatString from "sdk/tools/hooks/useFormatString";
import { useValidateEmail } from "hooks/useValidate";

function Email() {
  const formatString = useFormatString();

  const isValid = useValidateEmail();

  return (
    <WrapperField name="email" required={true} validate={{ isValid }}>
      <InputLegacyCard placeholder={formatString("email")} />
    </WrapperField>
  );
}

export default Email;
