import { IconsMaterial } from "sdk/components/Icon/Icons";
import React from "react";

function Icon({ icon = null, sx = null, viewBox = "0 0 24 24", className = null }) {
  const selectedIcon = IconsMaterial?.[icon];

  if (!selectedIcon) return;

  return React.cloneElement(selectedIcon, {
    sx,
    viewBox,
    className,
  });
}

export default Icon;
