import useFormatString from "sdk/tools/hooks/useFormatString";
import useWindowConfirm from "sdk/tools/hooks/useWindowConfirm";

function useAlertCloseConfirm(callback) {
  const formatString = useFormatString();

  const onCloseConfirm = useWindowConfirm(formatString("close_unsaved_alert_text"), callback);

  return onCloseConfirm;
}

export default useAlertCloseConfirm;
