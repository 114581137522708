import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { ReduxAction } from "sdk/tools/constants/Redux";

const filterSearchSlice = createSlice({
  name: "song/filterSearch",
  initialState: null,
  reducers: ReduxAction,
});

const filterSearchTypeSlice = createSlice({
  name: "song/filterSearchType",
  initialState: 0,
  reducers: ReduxAction,
});

const filterServiceSlice = createSlice({
  name: "song/filterService",
  initialState: null,
  reducers: ReduxAction,
});

const filterCertificationSlice = createSlice({
  name: "song/filterCertification",
  initialState: null,
  reducers: ReduxAction,
});

const filterTagSlice = createSlice({
  name: "song/filterTag",
  initialState: [],
  reducers: ReduxAction,
});

const filterMetadataSlice = createSlice({
  name: "song/filterMetadata",
  initialState: 0,
  reducers: ReduxAction,
});

export const filterSearchActions = filterSearchSlice.actions;
export const filterSearchTypeActions = filterSearchTypeSlice.actions;
export const filterServiceActions = filterServiceSlice.actions;
export const filterCertificationActions = filterCertificationSlice.actions;
export const filterTagActions = filterTagSlice.actions;
export const filterMetadataActions = filterMetadataSlice.actions;

export const filterSearchSelector = createSelector(
  (state) => state,
  (state) => state.song.filterSearch
);

export const filterSearchTypeSelector = createSelector(
  (state) => state,
  (state) => state.song.filterSearchType
);

export const filterServiceSelector = createSelector(
  (state) => state,
  (state) => state.song.filterService
);

export const filterCertificationSelector = createSelector(
  (state) => state,
  (state) => state.song.filterCertification
);

export const filterTagSelector = createSelector(
  (state) => state,
  (state) => state.song.filterTag
);

export const filterMetadataSelector = createSelector(
  (state) => state,
  (state) => state.song.filterMetadata
);

const songReducer = combineReducers({
  filterSearch: filterSearchSlice.reducer,
  filterSearchType: filterSearchTypeSlice.reducer,
  filterService: filterServiceSlice.reducer,
  filterCertification: filterCertificationSlice.reducer,
  filterTag: filterTagSlice.reducer,
  filterMetadata: filterMetadataSlice.reducer,
});

export default songReducer;
