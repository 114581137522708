import { defaultApiOptions, defaultAuthApiOptions, multipartAuthApiOptions, version } from "sdk/services/settings";

export const batchesGet = async (params, signal) => {
  const { data: batches } = await defaultAuthApiOptions.get(`/v${version}/batches`, {
    params: params,
    signal: signal,
  });
  return batches;
};

export const batchesBatchPost = async (body) => {
  const { data: batch } = await defaultAuthApiOptions.post(`/v${version}/batches/batch`, body);
  return batch;
};

export const batchesBatchGet = async (params, signal) => {
  const { data: batch } = await defaultAuthApiOptions.get(`/v${version}/batches/batch`, {
    params: params,
    signal: signal,
  });
  return batch;
};

export const batchesBatchDelete = async ({ batchId }) => {
  await defaultAuthApiOptions.delete(`/v${version}/batches/batch?batchId=${batchId}`);
};

export const batchesDocumentsPreparePost = (params) => {
  return defaultAuthApiOptions.get(`/v${version}/batches/documents/prepare`, {
    params: params,
  });
};

export const batchesDocumentsPreviewGet = async (params, signal) => {
  let { data: documents } = await defaultAuthApiOptions.get(`/v${version}/batches/documents/preview`, {
    params: params,
    signal: signal,
  });
  return documents;
};

export const batchesDocumentsTemplateContentGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/batches/documents/template-content`, {
    params: params,
    signal: signal,
  });
  return data?.content;
};

export const citiesGet = async () => {
  const { data: cities } = await defaultApiOptions.get(`/v${version}/cities`);
  if (!cities?.length) return [];
  let list = [...cities];
  return list.sort((a, b) => {
    const aTranslate = a?.name;
    const bTranslate = b?.name;
    if (aTranslate < bTranslate) return -1;
    if (aTranslate > bTranslate) return 1;
    return 0;
  });
};

export const collectingsGet = async () => {
  const { data: collectings } = await defaultApiOptions.get(`/v${version}/collectings`);
  return collectings;
};

export const countriesGet = async () => {
  const { data: countries } = await defaultApiOptions.get(`/v${version}/countries`);
  return countries;
};

export const credentialsPassword = async (body) => {
  await defaultAuthApiOptions.post(`/v${version}/credentials/password`, body);
};

export const documentsGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/documents`, {
    params: params,
    signal: signal,
  });
  return data;
};

export const documentsDownloadGet = async ({ id }) => {
  const payload = { id };
  const { data } = await defaultAuthApiOptions.get(`/v${version}/documents/download`, {
    params: payload,
  });
  return data?.link;
};

export const documentsPost = async ({ fileName, type, document }) => {
  const payload = { fileName, type, document };
  const { data } = await multipartAuthApiOptions.post(`/v${version}/documents`, payload);
  return data;
};

export const documentsSignGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/documents/sign`, {
    params: params,
    signal: signal,
  });
  return data?.link;
};

export const documentsSignersGet = async (params, signal) => {
  const { data: signers } = await defaultAuthApiOptions.get(`/v${version}/documents/signers`, {
    params: params,
    signal: signal,
  });
  return signers;
};

export const documentsTemplateContentGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/documents/template-content`, {
    params: params,
    signal: signal,
  });
  return data?.content;
};

export const economicActivityCodesGet = async () => {
  const { data: codes } = await defaultApiOptions.get(`/v${version}/economic-activity-codes`);
  return codes;
};

export const genresGet = async () => {
  const { data: genres } = await defaultAuthApiOptions.get(`/v${version}/genres`);
  return genres;
};

export const otpEmailValidatePost = async ({ contact, otp, locale }) => {
  const payload = { contact, otp, locale };
  await defaultAuthApiOptions.post(`/v${version}/otp/email/validate`, payload);
};

export const otpEmailGeneratePost = async ({ contact, locale }) => {
  const payload = { contact, locale };
  await defaultAuthApiOptions.post(`/v${version}/otp/email/generate`, payload);
};

export const otpSmsGeneratePost = async (body) => {
  await defaultAuthApiOptions.post(`/v${version}/otp/sms/generate`, body);
};

export const otpSmsValidatePost = async (body) => {
  await defaultAuthApiOptions.post(`/v${version}/otp/sms/validate`, body);
};

export const otpTransactionValidatePost = async (body) => {
  await defaultApiOptions.post(`/v${version}/otp/transaction/validate`, body);
};

export const monitoringFacetsPost = async (body, signal) => {
  const { data } = await defaultAuthApiOptions.post(`/v${version}/monitoring/facets`, body, {
    signal: signal,
  });
  return data;
};

export const monitoringSearchPost = async (body, signal) => {
  const { data: monitoring } = await defaultAuthApiOptions.post(`/v${version}/monitoring/search`, body, {
    signal: signal,
  });
  return monitoring;
};

export const paymentMethodTypeGet = async () => {
  const { data: types } = await defaultApiOptions.get(`/v${version}/payment-method-types`);
  return types;
};

export const paymentsAdvancesGet = async () => {
  const { data: advance } = await defaultAuthApiOptions.get(`/v${version}/payments/advances`);
  return advance;
};

export const paymentsBalanceCheckoutPost = async (body) => {
  await defaultAuthApiOptions.post(`/v${version}/payments/balance/checkout`, body);
};

export const paymentsBalanceGet = async () => {
  const { data: balance } = await defaultAuthApiOptions.get(`/v${version}/payments/balance`);
  return balance;
};

export const paymentsGet = async (params, signal) => {
  const { data: payment } = await defaultAuthApiOptions.get(`/v${version}/payments`, {
    params: params,
    signal: signal,
  });
  return payment;
};

export const paymentsPaydaysGet = async (params, signal) => {
  const { data: paydays } = await defaultAuthApiOptions.get(`/v${version}/payments/paydays`, {
    params: params,
    signal: signal,
  });
  return paydays;
};

export const paymentsPaydaysPerformanceGet = async (params, signal) => {
  const { data: performance } = await defaultAuthApiOptions.get(`/v${version}/payments/paydays/performance`, {
    params: params,
    signal: signal,
  });
  return performance;
};

export const rightHoldersRecentGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/right-holders/recent`, {
    params: params,
    signal: signal,
  });
  return data;
};

export const rightHoldersRoleGet = async () => {
  const { data: roles } = await defaultAuthApiOptions.get(`/v${version}/right-holders/roles`);
  return roles;
};

export const rightHoldersSearchGet = async (params, signal) => {
  let { data } = await defaultAuthApiOptions.get(`/v${version}/right-holders/search`, {
    params: params,
    signal: signal,
  });
  return data?.data;
};

export const royaltiesDigestLiveEventGet = (params, signal) => {
  return defaultAuthApiOptions.get(`/v${version}/royalties/digest-live-event`, { params: params, signal: signal });
};

export const royaltiesDigestDspGet = (params, signal) => {
  return defaultAuthApiOptions.get(`/v${version}/royalties/digest-dsp`, { params: params, signal: signal });
};

export const royaltiesDigestQuarterGet = async (params, signal) => {
  let { data: quarter } = await defaultAuthApiOptions.get(`/v${version}/royalties/digest-quarter`, {
    params: params,
    signal: signal,
  });
  return quarter;
};

export const royaltiesDigestLabelGet = (params, signal) => {
  return defaultAuthApiOptions.get(`/v${version}/royalties/digest-label`, { params: params, signal: signal });
};

export const royaltiesDigestBroadcasterGet = (params, signal) => {
  return defaultAuthApiOptions.get(`/v${version}/royalties/digest-broadcaster`, { params: params, signal: signal });
};

export const royaltiesDigestRadioChannelGet = (params, signal) => {
  return defaultAuthApiOptions.get(`/v${version}/royalties/digest-radio-channel`, { params: params, signal: signal });
};

export const royaltiesDigestSongGet = async (params, signal) => {
  let { data: song } = await defaultAuthApiOptions.get(`/v${version}/royalties/digest-song`, {
    params: params,
    signal: signal,
  });
  return song;
};

export const royaltiesDigestServiceGet = async (params, signal) => {
  const { data: royalty } = await defaultAuthApiOptions.get(`/v${version}/royalties/digest-service`, {
    params: params,
    signal: signal,
  });
  return royalty;
};

export const royaltiesDigestServiceSubTypeGet = async (params, signal) => {
  const { data: subType } = await defaultAuthApiOptions.get(`/v${version}/royalties/digest-service-subtype`, {
    params: params,
    signal: signal,
  });
  return subType;
};

export const royaltiesDigestTagGet = async (params, signal) => {
  const { data: tag } = await defaultAuthApiOptions.get(`/v${version}/royalties/digest-tag`, {
    params: params,
    signal: signal,
  });
  return tag;
};

export const royaltiesDigestTerritoryGet = async (params, signal) => {
  let { data: country } = await defaultAuthApiOptions.get(`/v${version}/royalties/digest-territory`, {
    params: params,
    signal: signal,
  });
  return country;
};

export const royaltiesFacetsPost = async (body, signal) => {
  const { data: facets } = await defaultAuthApiOptions.post(`/v${version}/royalties/facets`, body, { signal: signal });
  return facets;
};

export const royaltiesSearchPost = (body, params, signal) => {
  return defaultAuthApiOptions.post(`/v${version}/royalties/search`, body, { params: params, signal: signal });
};

export const servicesGet = async () => {
  let { data: service } = await defaultAuthApiOptions.get(`/v${version}/services`);
  return service;
};

export const servicesStatusGet = async (params, signal) => {
  const { data: servicesStatus } = await defaultAuthApiOptions.get(`/v${version}/services/status`, {
    params: params,
    signal: signal,
  });
  return servicesStatus;
};

export const settingsGet = async ({ key, category }) => {
  const payload = { key, category };
  const { data: settings } = await defaultAuthApiOptions.get(`/v${version}/settings`, {
    params: payload,
  });
  return settings;
};

export const songsGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/songs`, {
    params: params,
    signal: signal,
  });
  return data;
};

export const songsPost = async (body) => {
  const { data } = await defaultAuthApiOptions.post(`/v${version}/songs`, body);
  return data;
};

export const songsStatsGet = async () => {
  const { data: song } = await defaultApiOptions.get(`/v${version}/songs/stats`);
  return song;
};

export const songsDocumentsGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/songs/documents`, {
    params: params,
    signal: signal,
  });
  return data;
};

export const songCertificationsGet = async (params, signal) => {
  const { data: total } = await defaultAuthApiOptions.get(`/v${version}/songs/certifications`, {
    params: params,
    signal: signal,
  });
  return total;
};

export const songsCertificationsPost = async ({ recording }) => {
  const { data } = await defaultAuthApiOptions.post(`/v${version}/songs/certifications`, recording);
  return data;
};

export const songsCertificationsDownloadPost = (params) => {
  return defaultAuthApiOptions.get(`/v${version}/songs/certifications/download`, {
    params: params,
  });
};

export const songsCertificationsSuggestionsGet = async (params, signal) => {
  const { data: suggested } = await defaultAuthApiOptions.get(`/v${version}/songs/certifications/suggestions`, {
    params: params,
    signal: signal,
  });
  return suggested;
};

export const songsRecordingsPost = async (body) => {
  const { data } = await defaultAuthApiOptions.post(`/v${version}/songs/recordings`, body);
  return data;
};

export const songsRecordingsAudioDownloadGet = async (params) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/songs/recordings/audio/download`, {
    params: params,
  });
  return data?.link;
};

export const songsRecordingsMultipartPost = async (songId, recordingId, body) => {
  const { data } = await multipartAuthApiOptions.post(
    `/v${version}/songs/recordings/audio?songId=${songId}&recordingId=${recordingId}`,
    body
  );
  return data;
};

export const songsRecordingsDelete = async ({ recordingId }) => {
  await defaultAuthApiOptions.delete(`/v${version}/songs/recordings?recordingId=${recordingId}`);
};

export const songsRightHoldersGet = async (params, signal) => {
  const { data } = await defaultAuthApiOptions.get(`/v${version}/songs/right-holders`, {
    params: params,
    signal: signal,
  });
  return data;
};

export const songsServicesGet = async (params, signal) => {
  const { data: services } = await defaultAuthApiOptions.get(`/v${version}/songs/services`, {
    params: params,
    signal: signal,
  });

  return services;
};

export const songsRecordingsGet = async (params, signal) => {
  const { data: recordings } = await defaultAuthApiOptions.get(`/v${version}/songs/recordings`, {
    params: params,
    signal: signal,
  });
  return recordings;
};

export const songsTagsGet = async (params, signal) => {
  const { data: tags } = await defaultAuthApiOptions.get(`/v${version}/songs/tags`, {
    params: params,
    signal: signal,
  });
  return tags;
};

export const songsTagsPost = async (songId, body) => {
  const { data: tags } = await defaultAuthApiOptions.post(`/v${version}/songs/tags?songId=${songId}`, body);
  return tags;
};

export const tokenGeneratePost = async ({ email, password, realm }) => {
  const payload = { email, password, realm };
  const { data } = await defaultAuthApiOptions.post(`/v${version}/token/generate`, payload);
  return data?.token;
};

export const tagsGet = async () => {
  const { data: tags } = await defaultAuthApiOptions.get(`/v${version}/tags`);
  return tags;
};

export const taxRegimesGet = async () => {
  const { data: regimes } = await defaultApiOptions.get(`/v${version}/tax-regimes`);
  return regimes;
};

export const tokenRefreshPost = async () => {
  const { data } = await defaultAuthApiOptions.post(`/v${version}/token/refresh`);
  return data?.token;
};

export const tokenSwitchPost = async ({ targetUserId, realm }) => {
  const payload = { targetUserId, realm };
  const { data } = await defaultAuthApiOptions.post(`/v${version}/token/switch`, payload);
  return data?.token;
};

export const usageNotificationGet = async (params, signal) => {
  const { data: types } = await defaultAuthApiOptions.get(`/v${version}/usage-notification`, {
    params: params,
    signal: signal,
  });
  return types;
};

export const usageNotificationPost = async (body) => {
  const { data: notification } = await defaultAuthApiOptions.post(`/v${version}/usage-notification`, body);
  return notification;
};

export const usageNotificationUsageNotificationTypesGet = async () => {
  const { data: types } = await defaultAuthApiOptions.get(`/v${version}/usage-notification/usage-notification-types`);
  return types;
};

export const usageNotificationUsageTypesGet = async () => {
  const { data: types } = await defaultAuthApiOptions.get(`/v${version}/usage-notification/usage-types`);
  return types;
};

export const usersPost = async (body) => {
  await defaultAuthApiOptions.post(`/v${version}/users`, body);
};

export const usersMeGet = async () => {
  const { data: user } = await defaultAuthApiOptions.get(`/v${version}/users/me`);
  return user;
};

export const usersMePost = async (body) => {
  const { data: user } = await defaultAuthApiOptions.post(`/v${version}/users/me`, body);
  return user;
};

export const usersActivateAccountDocumentPost = async (body) => {
  await multipartAuthApiOptions.post(`/v${version}/users/activate-account`, body);
};

export const usersActivateAccountPost = async () => {
  await defaultAuthApiOptions.post(`/v${version}/users/activate-account`);
};

export const usersCloseAccountPost = async () => {
  await defaultAuthApiOptions.post(`/v${version}/users/close-account`);
};

export const usersContactsPost = async (body) => {
  const { data: contact } = await defaultAuthApiOptions.post(`/v${version}/users/contacts`, body);
  return contact;
};

export const usersEmailValidatePost = async (body) => {
  await defaultApiOptions.post(`/v${version}/users/email/validate`, body);
};

export const usersFiscalCodeValidatePost = async (body) => {
  await defaultApiOptions.post(`/v${version}/users/fiscal-code/validate`, body);
};

export const usersForgotPost = async ({ email, locale }) => {
  await defaultAuthApiOptions.post(`/v${version}/users/forgot?email=${email}&language=${locale}`);
  return { email, locale };
};

export const usersIbanValidatePost = async (body) => {
  await defaultApiOptions.post(`/v${version}/users/iban/validate`, body);
};

export const usersPermissionsGet = (params) => {
  return defaultAuthApiOptions.get(`/v${version}/users/permissions`, {
    params: params,
  });
};

export const usersRightTypesGet = async (params, signal) => {
  const { data: rights } = await defaultAuthApiOptions.get(`/v${version}/users/right-types`, {
    params: params,
    signal: signal,
  });
  return rights;
};

export const usersSettingsGet = async (params, signal) => {
  const { data: preferences } = await defaultAuthApiOptions.get(`/v${version}/users/settings`, {
    params: params,
    signal: signal,
  });
  return preferences;
};

export const usersSettingsPost = async (body) => {
  const preference = await defaultAuthApiOptions.post(`/v${version}/users/settings`, body);
  return preference;
};

export const usersVatNumberValidatePost = async (body) => {
  await defaultApiOptions.post(`/v${version}/users/vat-number/validate`, body);
};
