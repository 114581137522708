import { useState } from "react";
import useResize from "sdk/tools/hooks/useResize";

function useViewDesktop() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1080 ? true : false);

  const isDesktopHandler = () => {
    if (window.innerWidth < 1080 && isDesktop) setIsDesktop(false);
    if (window.innerWidth >= 1080 && !isDesktop) setIsDesktop(true);
  };

  useResize(isDesktopHandler);

  return isDesktop;
}

export default useViewDesktop;
