import { useState } from "react";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function Image({ src = null, alt = null, opacity = 1, className = null, onClick = null }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "transition-opacity duration-fastDuration",
    className,
  });

  const style = {
    opacity: imageLoaded ? opacity : 0,
  };

  return (
    <img src={src} alt={alt} style={style} {...classNames} onLoad={() => setImageLoaded(true)} onClick={onClick} />
  );
}

export default Image;
