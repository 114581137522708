import Public from "app/Route/Public/Public";
import BannerAppMobile from "components/components/Banner/BannerAppMobile";
import DeveloperGuard from "components/guards/DeveloperGuard";
import AppProviders from "components/providers/AppProviders";
import { reactLazyImportDelay } from "constants/Library";
import { customPath, path, toPath } from "constants/Route";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadScreen from "sdk/components/Loader/LoadScreen";
import ToastifyProvider from "sdk/providers/ToastifyProvider";

const Protected = React.lazy(() => {
  return Promise.all([
    import("app/Route/Protected/Protected"),
    new Promise((resolve) => setTimeout(resolve, reactLazyImportDelay)),
  ]).then(([moduleExports]) => moduleExports);
});
const ResetPassword = React.lazy(() => import("app/Route/ResetPassword/ResetPassword"));
const Embody = React.lazy(() => import("app/Route/Embody/Embody"));
const Report = React.lazy(() => import("app/Route/Report/Report"));

function App() {
  return (
    <AppProviders>
      <BannerAppMobile />
      <AppRoute />
      <ToastifyProvider />
    </AppProviders>
  );
}

function AppRoute() {
  return (
    <Routes>
      <Route path={`${path.PUBLIC}/*`} element={<Public />} />
      <Route
        path={`${path.PROTECTED}/*`}
        element={
          <Suspense fallback={<LoadScreen progress={20} />}>
            <Protected />
          </Suspense>
        }
      />
      <Route
        path={`${path.RESET_PASSWORD}/:${customPath.locale}/:${customPath.otp}`}
        element={
          <Suspense>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path={`${path.SSO}/*`}
        element={
          <Suspense fallback={<LoadScreen />}>
            <Embody />
          </Suspense>
        }
      />
      <Route
        path={`${path.REPORT}/*`}
        element={
          <DeveloperGuard navigateTo={toPath.publicSignIn}>
            <Suspense fallback={<LoadScreen />}>
              <Report />
            </Suspense>
          </DeveloperGuard>
        }
      />
      <Route path="*" element={<Navigate to={path.PUBLIC} relative="path" />} />
    </Routes>
  );
}

export default App;
