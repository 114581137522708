import AuthProvider from "components/providers/AuthProvider";
import UrlProvider from "components/providers/UrlProvider";
import { messages } from "i18n/messages";
import { Provider as ReduxProvider } from "react-redux";
import Theme from "sdk/constants/Theme";
import ThemeProvider from "sdk/providers/ThemeProvider";
import LocaleProvider from "sdk/tools/providers/LocaleProvider";
import QueryProvider from "sdk/tools/providers/QueryProvider";
import RouteProvider from "sdk/tools/providers/RouteProvider";
import store from "store/store";

function AppProviders({ children }) {
  return (
    <ReduxProvider store={store}>
      <LocaleProvider messages={messages}>
        <QueryProvider>
          <RouteProvider>
            <ThemeProvider defaultValue={Theme.dark}>
              <UrlProvider>
                <AuthProvider>{children}</AuthProvider>
              </UrlProvider>
            </ThemeProvider>
          </RouteProvider>
        </QueryProvider>
      </LocaleProvider>
    </ReduxProvider>
  );
}

export default AppProviders;
