export const documentType = {
  taxCertificate: "tax_certificate",
  payoutInvoice: "payout_invoice",
};

export const documentStatus = {
  uploaded: "UPLOADED",
  signed: "SIGNED",
  waiting: "WAITING_FOR_SIGNATURE",
  cancelled: "CANCELLED",
};

export const documentFilterType = {
  all_categories: "all_categories",
  payout: "payout",
  music: "music",
  account: "account",
};
