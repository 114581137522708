import { toPath } from "constants/Route";
import ButtonParagraph from "sdk/components/Button/ButtonParagraph";
import useFormatString from "sdk/tools/hooks/useFormatString";
import useStableNavigate from "sdk/tools/hooks/useStableNavigate";

function Recover() {
  const formatString = useFormatString();
  const navigate = useStableNavigate();

  const onClickHandler = () => navigate(toPath.publicForgot);

  return (
    <ButtonParagraph
      className="text-sm mt-2 font-bold text-textMedium hover:text-textWhite"
      clickable={true}
      placeholder={formatString("recover_password")}
      onClick={onClickHandler}
    />
  );
}

export default Recover;
