import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { memo } from "react";

const ErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -12],
            },
          },
        ],
      },
    }}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#27292a",
    color: "#ff4e55",
    fontWeight: "medium",
    fontSize: 12,
    textAlign: "center",
  },
}));

export default memo(ErrorTooltip);
