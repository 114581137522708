export const firebaseUserProperties = {
  service_group: "service_group",
  type: "type",
  status: "status",
  zero_royalty: "zero_royalty",
};

export const firebaseLogEvents = {
  checkout_completed: "checkout_completed",
  document_signature_completed: "document_signature_completed",
  music_registration_start: "music_registration_start",
  user_registration_completed: "user_registration_completed",
};
