import ButtonIcon from "sdk/components/Button/ButtonIcon";
import { Icons } from "sdk/components/Icon/Icons";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function HeaderDialog({ placeholder = null, onBack = null, onClose = null, className = null, children }) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "w-full flex gap-3 mb-4",
    className,
  });

  return (
    <div {...classNames}>
      {onBack && <ButtonIcon className="w-4 h-8" sx={{ fontSize: 28 }} icon={Icons.chevronLeft} onClick={onBack} />}
      <div className="grow min-w-0">
        <p className="text-lg leading-8 line-clamp-2 font-bold">{placeholder}</p>
        {children}
      </div>
      {onClose && <ButtonIcon className="w-4 h-8" sx={{ fontSize: 24 }} icon={Icons.close} onClick={onClose} />}
    </div>
  );
}

export default HeaderDialog;
