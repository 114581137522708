export const path = {
  BATCH: "batch",
  BLOCKCHAIN: "blockchain",
  COUNTRY: "country",
  DETAIL: "detail",
  DOCUMENT: "document",
  EDIT: "edit",
  EDIT_ADDRESS: "edit-address",
  EDIT_PAYMENT_METHOD: "edit-payment-method",
  EDIT_NUMBER: "edit-number",
  FORGOT: "forgot",
  HISTORY: "history",
  LIVE_EVENT: "live-event",
  HOME: "home",
  MONITORING: "monitoring",
  NEW: "new",
  OVERVIEW: "overview",
  PAYMENT: "payment",
  PAYMENTINFO: "payment-info",
  PROFILE: "profile",
  PROTECTED: "protected",
  PUBLIC: "public",
  QUARTER: "quarter",
  RESET_PASSWORD: "reset-password",
  REGISTRATION: "registration",
  REPORT: "report",
  RIGHTHOLDER: "right-holder",
  ROYALTY: "royalty",
  SERVICE: "service",
  SETTINGS: "setting",
  SIGNIN: "signin",
  SIGNUP: "signup",
  SONG: "song",
  SSO: "sso",
  TAG: "tag",
  TERMINATE: "terminate",
  USAGE_NOTIFICATION: "usage-notification",
  USER: "user",
  VERSION: "version",
};

export const customPath = {
  batch: "batch",
  locale: "locale",
  otp: "otp",
  payday: "payday",
  service: "service",
  song: "song",
};

export const toPath = {
  publicSignIn: `/${path.PUBLIC}/${path.SIGNIN}`,
  publicSignUp: `/${path.PUBLIC}/${path.SIGNUP}`,
  publicForgot: `/${path.PUBLIC}/${path.FORGOT}`,
  protectedHome: `/${path.PROTECTED}/${path.HOME}`,
  protectedUsageNotification: `/${path.PROTECTED}/${path.USAGE_NOTIFICATION}`,
  protectedMonitoring: `/${path.PROTECTED}/${path.MONITORING}`,
  protectedMonitoringDetail: `/${path.PROTECTED}/${path.MONITORING}/${path.DETAIL}`,
  protectedMonitoringCountry: `/${path.PROTECTED}/${path.MONITORING}/${path.COUNTRY}`,
  protectedRoyalty: `/${path.PROTECTED}/${path.ROYALTY}`,
  protectedRoyaltyCountry: `/${path.PROTECTED}/${path.ROYALTY}/${path.COUNTRY}`,
  protectedRoyaltyQuarter: `/${path.PROTECTED}/${path.ROYALTY}/${path.QUARTER}`,
  protectedRoyaltyTag: `/${path.PROTECTED}/${path.ROYALTY}/${path.TAG}`,
  protectedRoyaltyDetail: `/${path.PROTECTED}/${path.ROYALTY}/${path.DETAIL}`,
  protectedPayment: `/${path.PROTECTED}/${path.PAYMENT}`,
  protectedPaymentDetail: `/${path.PROTECTED}/${path.PAYMENT}/${path.DETAIL}`,
  protectedPaymentHistory: `/${path.PROTECTED}/${path.PAYMENT}/${path.HISTORY}`,
  protectedSong: `/${path.PROTECTED}/${path.SONG}`,
  protectedSongDetail: `/${path.PROTECTED}/${path.SONG}/${path.DETAIL}`,
  protectedSongRegistration: `/${path.PROTECTED}/${path.SONG}/${path.REGISTRATION}`,
  protectedSongBatch: `/${path.PROTECTED}/${path.SONG}/${path.BATCH}`,
  protectedUserProfile: `/${path.PROTECTED}/${path.USER}/${path.PROFILE}`,
  protectedUserProfileEditAddress: `/${path.PROTECTED}/${path.USER}/${path.PROFILE}/${path.EDIT_ADDRESS}`,
  protectedUserProfileEditNumber: `/${path.PROTECTED}/${path.USER}/${path.PROFILE}/${path.EDIT_NUMBER}`,
  protectedUserProfileForgot: `/${path.PROTECTED}/${path.USER}/${path.PROFILE}/${path.FORGOT}`,
  protectedUserProfileTerminate: `/${path.PROTECTED}/${path.USER}/${path.PROFILE}/${path.TERMINATE}`,
  protectedUserDocument: `/${path.PROTECTED}/${path.USER}/${path.DOCUMENT}`,
  protectedUserPaymentInfo: `/${path.PROTECTED}/${path.USER}/${path.PAYMENTINFO}`,
  protectedUserPaymentInfoEditPaymentMethod: `/${path.PROTECTED}/${path.USER}/${path.PAYMENTINFO}/${path.EDIT_PAYMENT_METHOD}`,
  protectedUserSetting: `/${path.PROTECTED}/${path.USER}/${path.SETTINGS}`,
};
