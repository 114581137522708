import useDateAfter from "sdk/tools/hooks/useDateAfter";
import useDateBefore from "sdk/tools/hooks/useDateBefore";
import useDateByYear from "sdk/tools/hooks/useDateByYear";

function useDateSignUpLimit() {
  const dateByYear = useDateByYear();
  const dateBefore = useDateBefore();
  const dateAfter = useDateAfter();

  const dateLimit = (date) => {
    if (dateBefore(new Date(date), dateByYear(-120))) return false;
    if (dateAfter(new Date(date), dateByYear(0))) return false;
    return true;
  };

  return dateLimit;
}

export default useDateSignUpLimit;
