import { useCallback } from "react";
import useFormatString from "sdk/tools/hooks/useFormatString";

function useFormatLocale() {
  const formatString = useFormatString();

  const formatLocale = useCallback(
    (locale) => {
      return formatString(`language.${locale}.name`);
    },
    [formatString]
  );

  return formatLocale;
}

export default useFormatLocale;
