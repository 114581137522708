import { ServiceDTO } from "constants/DTO";
import useQueryGetData from "sdk/tools/hooks/useQueryGetData";
import { serviceQueryKey } from "services/queries/useQueryService";
import { useMemo } from "react";

function useServiceUiOrderSort(serviceToOrder) {
  const serviceOrder = useQueryGetData({ queryKey: serviceQueryKey });

  const orderedServices = useMemo(() => {
    if (!serviceToOrder?.length || !serviceOrder?.length) return [];

    let arrayToOrder = serviceToOrder.map((service) => {
      const serviceToFind = service?.[ServiceDTO.shortname];
      let findUiOrderNumber = serviceOrder?.find((x) => x?.[ServiceDTO.shortname] === serviceToFind);
      findUiOrderNumber = findUiOrderNumber?.[ServiceDTO.uiOrder];

      return { ...service, [ServiceDTO.uiOrder]: findUiOrderNumber };
    });

    if (!arrayToOrder || !arrayToOrder?.length) return undefined;

    return (arrayToOrder = arrayToOrder?.sort((a, b) => a?.[ServiceDTO.uiOrder] - b?.[ServiceDTO.uiOrder]));
  }, [serviceToOrder]);

  return orderedServices;
}

export default useServiceUiOrderSort;
