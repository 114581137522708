import { createContext, forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

export const ShowContext = createContext({});

function Show({ component = null, className = null, children }, ref) {
  const [isActive, setIsActive] = useState(false);

  const storageRef = useRef(null);
  const storage = storageRef.current;

  const open = useCallback((payload) => {
    setIsActive(true);
    if (payload) storageRef.current = payload;
  }, []);
  const close = useCallback(() => {
    setIsActive(false);
    storageRef.current = null;
  }, []);

  useImperativeHandle(ref, () => ({ isActive, open, close }));

  return (
    <ShowContext.Provider value={{ isActive, open, close, storage, component, className }}>
      {children}
      {isActive && component}
    </ShowContext.Provider>
  );
}

export default forwardRef(Show);
