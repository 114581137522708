export const linkEnvironment = "app";
export const linkEnvironmentAppend = linkEnvironment + "_";

export const externalLink = {
  openTimeStamps: "https://opentimestamps.org/",
  appStore: "https://apps.apple.com/it/app/soundreef/id1585664306#:~:text=Visualizza%20nel%20Mac-,App,-Store",
  appGooglePlayStore: "https://play.google.com/store/apps/details?id=com.soundreef.app.mrp&pcampaignid=web_share",
  soundreef: "https://soundreef.com",
};

export const linkKey = {
  url_privacy_policy: "url_privacy_policy",
  url_terms: "url_terms",
  url_faq: "url_faq",
  url_contacts: "url_contacts",
  url_help_calculation_mode: "url_help_calculation_mode",
  url_help_empty_royalties: "url_help_empty_royalties",
  url_help_music_registration: "url_help_music_registration",
  url_help_music_registration_in_store: "url_help_music_registration_in_store",
  url_help_song_certification_availability: "url_help_song_certification_availability",
  url_help_song_certification_proof: "url_help_song_certification_proof",
  url_help_payment_balance: "url_help_payment_balance",
  url_help_tax_residency: "url_help_tax_residency",
  url_help_collecting_registration: "url_help_collecting_registration",
  url_help_instore_registration: "url_help_instore_registration",
  url_help_band_registration: "url_help_band_registration",
  url_help_user_status_frozen_fill_form: "url_help_user_status_frozen_fill_form",
};
