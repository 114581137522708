import logoSoundreefLogin from "assets/logo-soundreef-horizontal.png";
import Image from "sdk/components/Generic/Image";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function SoundreefLogoHorizontal({ active = false, className = null, onClick = null }) {
  const classNames = useTwClassNames({
    active: active,
    activeClassName: "pointer-events-auto cursor-pointer",
    disabled: !active,
    disabledClassName: "pointer-events-none cursor-auto",
    className: className,
  });

  return <Image {...classNames} src={logoSoundreefLogin} alt="Soundreef Logo" onClick={onClick} />;
}

export default SoundreefLogoHorizontal;
