import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import Icon from "sdk/components/Icon/Icon";

function ContainerCard({
  disabled = false,
  error = false,
  rightIcon = null,
  rightIconProps = null,
  switcher = null,
  className = null,
  children,
}) {
  const containerClassNames = useTwClassNames({
    default: true,
    defaultClassName:
      "h-fit bg-fillCard border hover:bg-opacity-80 px-primaryPadding rounded-md border-transparent focus-within:!border-accentDark relative items-center flex w-full",
    error: !!error,
    errorClassName: "border-error",
    disabled,
    disabledClassName:
      "cursor-auto pointer-events-none has-[*:disabled]:text-textDisabledDark !border-transparent hover:border-textDisabledDark",
    className,
  });

  return (
    <div {...containerClassNames}>
      {children}
      <div className="h-full flex-none empty:hidden w-0 flex items-center justify-center">
        <Icon className="text-inherit" sx={{ fontSize: 18 }} icon={rightIcon} {...rightIconProps} />
      </div>
      {switcher}
    </div>
  );
}

export default ContainerCard;
