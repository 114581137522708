import { useMutation, useQueryClient } from "@tanstack/react-query";
import { tokenGeneratePost } from "sdk/services/endpoints";
import { authTokenQueryKey } from "services/queries/useQueryAuthToken";

export const authTokenGenerateMutationKey = ["protected", "auth", "token", "generate"];

function useMutationAuthTokenGenerate() {
  const queryClient = useQueryClient();

  const mutationFn = async ({ email, password, realm }) => {
    const payload = { email, password, realm };
    const token = await tokenGeneratePost(payload);
    return token;
  };

  const onSuccess = (payload) => queryClient.setQueryData(authTokenQueryKey, () => payload);

  const { mutate, mutateAsync, data, error, isPending, isError, isSuccess } = useMutation({
    mutationKey: authTokenGenerateMutationKey,
    mutationFn: mutationFn,
    onSuccess: onSuccess,
  });

  return { mutate, mutateAsync, data, error, isPending, isError, isSuccess };
}

export default useMutationAuthTokenGenerate;
