import { useEffect } from "react";

function useKeyUp(eventCode, callback) {
  useEffect(() => {
    function handleKeyUp(event) {
      if (event.code === eventCode) callback(event);
    }
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  });
}

export default useKeyUp;
