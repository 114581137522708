import { createContext, useContext, useEffect, useState } from "react";
import Theme from "sdk/constants/Theme";

const ThemeContext = createContext({});

function ThemeProvider({ defaultValue = null, children }) {
  const getInitialTheme = () => {
    if (defaultValue === Theme.detectFromBrowser) {
      const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      return prefersDarkMode ? Theme.dark : Theme.light;
    }
    if (defaultValue) return defaultValue;
    return Theme.dark;
  };

  const [theme, setTheme] = useState(getInitialTheme());

  useEffect(() => {
    if (theme === Theme.dark) {
      document.documentElement.classList.add(Theme.dark);
    } else {
      document.documentElement.classList.remove(Theme.dark);
    }
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  const { theme, setTheme } = useContext(ThemeContext);

  const switchTheme = () => setTheme(theme === Theme.dark ? Theme.light : Theme.dark);

  return { theme, setTheme, switchTheme };
}

export default ThemeProvider;
