import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import { memo } from "react";

function InputLegacyLabel({ name = null, disabled = false, error = false, placeholder = null, labelOnTop = false }) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName:
      "absolute ellipsis font-medium pointer-events-none w-[95%] text-textMedium text-base duration-fastDuration transition-label",
    labelOnTop,
    labelOnTopClassName: "-translate-y-3 text-xs",
    error: !!error,
    errorClassName: "text-error",
    disabled,
    disabledClassName: "text-textDisabledDark",
  });

  return (
    <label {...classNames} htmlFor={name}>
      {placeholder}
    </label>
  );
}

export default memo(InputLegacyLabel);
