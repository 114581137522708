import dayjs from "dayjs";
import { useCallback } from "react";

function useDateBefore() {
  const dateBefore = useCallback((date, limitDate) => {
    if (!date || !limitDate) return null;
    return dayjs(date).isBefore(limitDate);
  }, []);

  return dateBefore;
}

export default useDateBefore;
