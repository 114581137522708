function Shimmer() {
  return (
    <>
      <div className="absolute bottom-0 left-0 right-0 top-0 z-20 cursor-auto bg-fillCard" />
      <div className="absolute bottom-0 left-0 top-0 z-20 w-3/4 animate-shimmer cursor-auto bg-gradient-to-r from-transparent via-fillAppBackground to-transparent" />
    </>
  );
}

export default Shimmer;
