import { SvgIcon } from "@mui/material";
import { memo } from "react";

function LiveIcon(props) {
  return (
    <SvgIcon
      fontSize="inherit"
      sx={{
        color: "inherit",
      }}
      {...props}
      viewBox="0 14 52 24"
    >
      <path d="M26,4 C27.6568542,4 29,5.34314575 29,7 L29,13 L27.2269999,13 L29.5,23 L20.5,40 L15,40 L22.2319999,13 L20,13 L20,7 C20,5.34314575 21.3431458,4 23,4 L26,4 Z M42.8775328,4.77163147 L45.6390474,5.94382485 C47.1641897,6.59120938 47.8757531,8.35239042 47.2283685,9.8775328 L44.8839818,15.4005619 L43.3039999,14.729 L36.5,40 L24,40 L38.2069999,12.566 L36.5994381,11.8839818 L38.9438249,6.36095264 C39.5912094,4.83581026 41.3523904,4.12424694 42.8775328,4.77163147 Z M9.54695297,5.22098334 L13.8629918,9.38893357 L12.5769999,10.718 L19,15 L16,26 L9.15399988,14.264 L7.61106643,15.8629918 L3.29502763,11.6950415 C2.10318643,10.5440939 2.07003567,8.64488824 3.22098334,7.45304703 L5.30495845,5.29502763 C6.45590613,4.10318643 8.35511176,4.07003567 9.54695297,5.22098334 Z M3,43 L48,43 L48,46 L3,46 L3,43 Z"></path>
    </SvgIcon>
  );
}

export default memo(LiveIcon);
