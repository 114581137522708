import Background from "app/Route/Public/Component/Background";
import Navigation from "app/Route/Public/Component/Navigation";
import Placeholder from "app/Route/Public/Component/Placeholder";
import SignIn from "app/Route/Public/Route/SignIn/SignIn";
import Footer from "components/components/Footer/Footer";
import SoundreefLogoHorizontal from "components/components/Logo/SoundreefLogoHorizontal";
import PublicGuard from "components/guards/PublicGuard";
import { reactLazyImportDelay } from "constants/Library";
import { externalLink } from "constants/Link";
import { path, toPath } from "constants/Route";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import WrapperIconCircular from "sdk/components/Wrapper/WrapperIconCircular";

const SignUp = React.lazy(() => {
  return Promise.all([
    import("app/Route/Public/Route/SignUp/SignUp"),
    new Promise((resolve) => setTimeout(resolve, reactLazyImportDelay)),
  ]).then(([moduleExports]) => moduleExports);
});
const Forgot = React.lazy(() => {
  return Promise.all([
    import("app/Route/Public/Route/Forgot/Forgot"),
    new Promise((resolve) => setTimeout(resolve, reactLazyImportDelay)),
  ]).then(([moduleExports]) => moduleExports);
});

function Public() {
  return (
    <PublicGuard navigateTo={toPath.protectedHome}>
      <PublicLayout>
        <PublicRoute />
      </PublicLayout>
    </PublicGuard>
  );
}

function PublicLayout({ children }) {
  const onClickHandler = () => window.location.assign(externalLink.soundreef);

  return (
    <>
      <div className="relative min-w-screen min-h-screen w-full max-w-[120rem] mx-auto grid sm:grid-cols-1 lg:grid-cols-2">
        <main className="min-h-screen animate-fadeIn z-10 flex flex-col px-primaryPadding sm:pt-12 lg:pt-32 sm:col-span-1 col-start-1 lg:col-span-2 lg:col-start-2">
          <div className="h-7 w-fit mx-auto mb-8 sm:block lg:hidden">
            <SoundreefLogoHorizontal active={true} className="w-[14.375rem] h-auto" onClick={onClickHandler} />
          </div>
          <div className="h-full">{children}</div>
          <div className="sm:hidden lg:flex grow w-full flex justify-center items-end">
            <Navigation />
          </div>
          <div className="sm:flex lg:hidden grow w-full flex flex-col items-center justify-end">
            <Navigation />
            <Footer className="p-primaryPadding lg:hidden" />
          </div>
        </main>
      </div>
      <div className="sm:hidden lg:block fixed w-screen h-screen">
        <div className="w-full max-w-[120rem] h-full mx-auto grid grid-cols-2">
          <Placeholder />
          <Background />
        </div>
      </div>
    </>
  );
}

function PublicRoute() {
  return (
    <Routes>
      <Route path={`${path.SIGNIN}/*`} element={<SignIn />} />
      <Route
        path={`${path.SIGNUP}/*`}
        element={
          <Suspense fallback={<WrapperIconCircular />}>
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path={`${path.FORGOT}/*`}
        element={
          <Suspense fallback={<WrapperIconCircular />}>
            <Forgot />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to={toPath.publicSignIn} />} />
    </Routes>
  );
}

export default Public;
