import { setUserProperties, logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "firebaseConfig";

function useFirebase() {
  const userPropertySet = ({ key, value }) => {
    if (!key || !value) return;
    setUserProperties(firebaseAnalytics, { key: value });
  };

  const logEventSet = ({ key }) => {
    logEvent(firebaseAnalytics, key);
  };

  return { userPropertySet, logEventSet };
}

export default useFirebase;
