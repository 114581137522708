import { combineReducers, configureStore, createAction } from "@reduxjs/toolkit";
import detailReducer from "store/reducer/detailReducer";
import documentReducer from "store/reducer/documentReducer";
import monitoringReducer from "store/reducer/monitoringReducer";
import registrationReducer from "store/reducer/registrationReducer";
import royaltyReducer from "store/reducer/royaltyReducer";
import songReducer from "store/reducer/songReducer";
import usageNotificationReducer from "store/reducer/usageNotificationReducer";
import { audioSlice } from "store/slice/audioSlice";

const devTools = (() => {
  if (process.env.NODE_ENV === "development") return true;
  return false;
})();

export const cleanReducer = createAction("clean");

const resetAppReducer = combineReducers({
  audio: audioSlice.reducer,
  usageNotification: usageNotificationReducer,
  document: documentReducer,
  royalty: royaltyReducer,
  monitoring: monitoringReducer,
  song: songReducer,
  registration: registrationReducer,
  detail: detailReducer,
});

const appReducer = (state, action) => {
  if (cleanReducer.type === action.type) return resetAppReducer(undefined, action);
  return resetAppReducer(state, action);
};

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools,
});

export default store;
