const Regex = {
  email: RegExp(/(.+)@(.+)\.(.+)/i),
  onlyNumber: RegExp(/^\d+$/),
  onlyNumberDecimals: RegExp(/^\d*\.?\d*$/),
  percentage: RegExp(/^\d*\.?\d*$/),
  number: RegExp("[0-9]"),
  onlyCharacter: RegExp(/^([^0-9]*)$/),
  phoneNumber: RegExp("^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"),
  character: RegExp("[A-Za-z]"),
  specialCharacter: RegExp(/[#+$^=_!*()@%&-]/),
  passwordLength: RegExp("^.{8,49}$"),
};

export default Regex;
