import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import useInteraction from "sdk/tools/hooks/useInteraction";
import { memo } from "react";
import useFormatString from "sdk/tools/hooks/useFormatString";

function ButtonShowHide({ disabled = false, hidden = true, onChange = null }) {
  const formatString = useFormatString();

  const valueHandler = hidden ? formatString("show") : formatString("hide");

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "font-bold text-accentPrimary outline-none",
    active: !disabled,
    activeClassName: "hover:opacity-80 focus:text-accentDark pointer-events-auto cursor-pointer",
    disabled: disabled,
    disabledClassName: "text-textDisabledDark pointer-events-none cursor-auto",
  });

  const interactions = useInteraction({ callback: () => onChange(!hidden), disabled: disabled });

  return (
    <button type="button" {...classNames} {...interactions}>
      {valueHandler}
    </button>
  );
}

export default memo(ButtonShowHide);
