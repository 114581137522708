import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function Href({ href = null, placeholder = null, className = null }) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "w-fit text-textMedium outline-none",
    clickable: !!href,
    clickableClassName: "hover:text-textWhite focus:text-accentDark",
    disabled: !href,
    disabledClassName: "pointer-events-none cursor-auto",
    className: className,
  });

  const onClickHandler = (event) => event.stopPropagation();

  return (
    <a {...classNames} target="_blank" rel="noreferrer" onMouseUp={onClickHandler} href={href ?? null}>
      {placeholder}
    </a>
  );
}

export default Href;
