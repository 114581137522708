import { useQuery } from "@tanstack/react-query";
import { UserIndividualDTO } from "constants/DTO";
import { firebaseUserProperties } from "constants/Firebase";
import useFirebase from "hooks/useFirebase";
import { usersMeGet } from "sdk/services/endpoints";

export const userQueryKey = ["protected", "user"];

function useQueryUser(enabled = true) {
  const { userPropertySet } = useFirebase();

  const queryFn = async () => {
    const user = await usersMeGet();
    const userServiceGroup = user?.[UserIndividualDTO.serviceGroup];
    const userType = user?.[UserIndividualDTO.type];
    const userStatus = user?.[UserIndividualDTO.userStatus];
    if (userServiceGroup) userPropertySet({ key: firebaseUserProperties.service_group, value: userServiceGroup });
    if (userType) userPropertySet({ key: firebaseUserProperties.type, value: userType });
    if (userStatus) userPropertySet({ key: firebaseUserProperties.status, value: userStatus });
    return user;
  };

  const { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess } = useQuery({
    enabled,
    queryKey: userQueryKey,
    queryFn,
  });

  return { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess };
}

export default useQueryUser;
