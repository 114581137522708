import { twMerge } from "tailwind-merge";

function useTwClassNames(props) {
  let customClassNames = [];

  for (const [key, value] of Object.entries(props)) {
    if (typeof value === "boolean" && value === true && props?.[key + "ClassName"]) {
      customClassNames.push(props?.[key + "ClassName"]);
    }
  }

  props?.className && customClassNames.push(props?.className);

  return { className: twMerge(customClassNames) };
}

export default useTwClassNames;
