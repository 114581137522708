import ContainerCard from "sdk/components/Input/ContainerCard";
import InputLegacy from "sdk/components/Input/InputLegacy";
import { forwardRef } from "react";

function InputLegacyCard(
  {
    name = null,
    placeholder = null,
    type = null,
    disabled = false,
    defaultValue = "",
    error = false,
    rightIcon = null,
    rightIconProps = null,
    customProps = null,
    onTransform = null,
    onBlur = null,
    onChange = null,
    className = null,
  },
  ref
) {
  return (
    <ContainerCard
      disabled={disabled}
      error={error}
      rightIcon={rightIcon}
      rightIconProps={rightIconProps}
      className={className}
    >
      <InputLegacy
        ref={ref}
        name={name}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        error={error}
        customProps={customProps}
        onTransform={onTransform}
        onChange={onChange}
        onBlur={onBlur}
      />
    </ContainerCard>
  );
}

export default forwardRef(InputLegacyCard);
