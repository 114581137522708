import MenuIcon from "@mui/icons-material/Menu";
import { path } from "constants/Route";
import useInteraction from "sdk/tools/hooks/useInteraction";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import { ShowContext } from "sdk/tools/components/Show";
import { NavigationContext } from "components/providers/NavigationProvider";
import { useContext } from "react";

function HamburgerIcon() {
  const { activePage } = useContext(NavigationContext);
  const { open } = useContext(ShowContext);

  const classNames = useTwClassNames({
    home: activePage === path.HOME,
    homeClassName: "focus:text-textWhite",
    notHome: activePage !== path.HOME,
    notHomeClassName: "focus:text-accentDark",
    className:
      "pointer-events-auto cursor-pointer mb-[0.1rem] flex items-center justify-center text-xl outline-none hover:opacity-80",
  });

  const interactions = useInteraction({ callback: open });

  return (
    <button {...classNames} {...interactions}>
      <MenuIcon />
    </button>
  );
}

export default HamburgerIcon;
