import { FormProvider, useForm } from "react-hook-form";

function WrapperForm({ defaultValues = null, className = null, onSubmit = () => {}, children }) {
  const methods = useForm({ defaultValues: defaultValues });

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onSubmit={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          setTimeout(methods.handleSubmit(onSubmit), 0);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}

export default WrapperForm;
