import { useCallback } from "react";
import { useIntl } from "react-intl";

function useFormatString() {
  const intl = useIntl();

  const formatString = useCallback(
    (value, params) => {
      if (value) {
        if (!intl.messages[value]) return null;
        return intl.formatMessage(
          {
            id: value,
            defaultMessage: value,
          },
          params
        );
      }
    },
    [intl]
  );

  return formatString;
}

export default useFormatString;
