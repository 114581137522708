import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/it";
import { createContext, useCallback, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";

export const LocaleContext = createContext({});

function LocaleProvider({ messages, children }) {
  const availableLocales = useMemo(() => Object.keys(messages), [messages]);

  const getBrowserLocale = useCallback(() => {
    let language = "en";
    const browserLanguage = navigator.language.slice(0, 2).toLowerCase();
    if (availableLocales?.includes(browserLanguage)) {
      language = browserLanguage;
      document.documentElement.lang = language;
    }
    return language;
  }, [availableLocales]);

  const setBrowserLocale = () => setLocale(getBrowserLocale());

  const [locale, setLocale] = useState(getBrowserLocale());

  document.documentElement.lang = locale;
  dayjs.locale(locale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <LocaleContext.Provider value={{ locale, setLocale, getBrowserLocale, setBrowserLocale, availableLocales }}>
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
}

export default LocaleProvider;
