export const royaltyGroupBy = {
  service: "service",
  serviceSubtype: "serviceSubtype",
  payment: "payment",
  song: "song",
  territory: "territory",
  license: "license",
  customer: "customer",
  radioChannel: "radio-channel",
};

export const royaltySortBy = {
  royalties: "royalties",
  usageCount: "usageCount",
  date: "date",
};

export const royaltyLicenseType = {
  liveEvent: "liveEvent",
  radioChannel: "radioChannel",
};
