import Hidden from "sdk/tools/components/Hidden";

function Paragraph({ hidden = false, placeholder = null, className = null }) {
  return (
    <Hidden hidden={hidden}>
      <p className={className}>{placeholder}</p>
    </Hidden>
  );
}

export default Paragraph;
