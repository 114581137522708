export const sortByDirection = {
  ascendant: "+",
  descendant: "-",
};

export const apiErrorTest = async (status, ms, errorCode) => {
  throw { response: { status: status ?? 422, data: { errors: [{ errorCode: errorCode ?? "error.generic" }] } } };
};

export const reactLazyImportDelay = 1000;
