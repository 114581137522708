export const userSettingKey = {
  language: "language",
  discreetMode: "discreetMode",
  notifications_email_documents_waitingForSignature: "notifications_email_documents_waitingForSignature",
  notifications_push_documents_waitingForSignature: "notifications_push_documents_waitingForSignature",
  notifications_email_royalties_new: "notifications_email_royalties_new",
  notifications_push_royalties_new: "notifications_push_royalties_new",
  notifications_email_marketing_newsletter: "notifications_email_marketing_newsletter",
  notifications_push_marketing_newsletter: "notifications_push_marketing_news",
};
