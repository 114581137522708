import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import useInteraction from "sdk/tools/hooks/useInteraction";

function ButtonParagraph({
  clickable = true,
  accentColors = false,
  disabled = false,
  placeholder = null,
  onClick = null,
  className = null,
}) {
  const isClickable = clickable && !disabled;

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "text-left outline-none",
    isClickable,
    isClickableClassName: "focus:text-accentDark hover:opacity-80",
    accentColors,
    accentColorsClassName: "text-accentPrimary",
    className,
  });

  const interactions = useInteraction({ disabled: !isClickable, callback: onClick });

  return (
    <button {...classNames} {...interactions} disabled={!isClickable}>
      {placeholder}
    </button>
  );
}

export default ButtonParagraph;
