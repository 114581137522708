import { CustomKeyDTO } from "constants/DTO";
import Dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import useLocalStorage from "sdk/tools/hooks/useLocalStorage";

function useLocalStorageToken() {
  const { getItem, setItem, removeItem } = useLocalStorage();

  const getToken = () => getItem([CustomKeyDTO.token]);
  const setToken = (token) => setItem([CustomKeyDTO.token], token);
  const removeToken = () => removeItem([CustomKeyDTO.token]);

  // call the function with async/await to get the result
  const getTokenIsExpired = async () => {
    const currentToken = getToken();
    if (!currentToken) return true;
    try {
      let exp = jwtDecode(currentToken)?.exp;
      if (!exp) return true;
      let expDate = new Date(0);
      expDate.setUTCSeconds(exp);
      expDate = Dayjs(expDate);
      const today = Dayjs();
      const diff = expDate.diff(today, "days");
      if (diff < 0) return true;
      return false;
    } catch (error) {
      return false;
    }
  };

  // call the function with async/await to get the result
  const getTokenHasToBeRefreshed = async () => {
    const currentToken = getToken();
    if (!currentToken) return false;
    try {
      let exp = jwtDecode(currentToken)?.exp;
      if (!exp) return true;
      let expDate = new Date(0);
      expDate.setUTCSeconds(exp);
      expDate = Dayjs(expDate);
      const today = Dayjs();
      const diff = expDate.diff(today, "days");
      if (diff < 5) return true;
      return false;
    } catch (error) {
      return false;
    }
  };

  return { getToken, setToken, removeToken, getTokenIsExpired, getTokenHasToBeRefreshed };
}

export default useLocalStorageToken;
