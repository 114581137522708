import Regex from "sdk/tools/constants/Regex";
import useFormatDate from "sdk/tools/hooks/useFormatDate";
import useDateSignUpLimit from "hooks/useDateSignUpLimit";

export function useValidateDateLimit() {
  const formatDate = useFormatDate();
  const dateSignLimit = useDateSignUpLimit();

  const isValid = (value) => {
    if (!formatDate(value)) return false;
    if (!dateSignLimit(value)) return "birth_company_date_range_error";
    return true;
  };

  return isValid;
}

export function useValidateEmail() {
  const isValid = (value) => {
    if (!value?.match(Regex.email)) return "invalid_email";
    return true;
  };

  return isValid;
}

export function useValidateEmailMatch(password) {
  const isValid = (value) => {
    if (password !== value) return "email_not_match";
    return true;
  };

  return isValid;
}

export function useValidatePassword() {
  const isValid = (value) => {
    if (!value?.match(Regex.passwordLength)) return "account_information_password_hint_2";
    if (!value?.match(Regex.character)) return "account_information_password_hint_3";
    if (!value?.match(Regex.number)) return "account_information_password_hint_4";
    if (!value?.match(Regex.specialCharacter)) return "account_information_password_hint_5";
    return true;
  };

  return isValid;
}

export function useValidatePhoneNumber() {
  const isValid = (value) => {
    if (!value?.match(Regex.phoneNumber)) return "invalid_phone_number";
    return true;
  };

  return isValid;
}
