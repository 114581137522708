import { useMutationState } from "@tanstack/react-query";

function useMutationPending({ mutationKey }) {
  const [isPending] = useMutationState({
    filters: { mutationKey: mutationKey, status: "pending" },
    select: (mutation) => mutation.state.status === "pending",
  });

  return !!isPending;
}

export default useMutationPending;
