import WrapperLegacy from "sdk/components/Wrapper/WrapperLegacy";
import Layer from "sdk/tools/components/Layer";

function WrapperDialog({ onClose = null, children }) {
  return (
    <Layer
      className="grid animate-fadeIn grid-cols-1 justify-center items-center bg-fillDarkOverlay py-16"
      onClick={onClose}
    >
      <WrapperLegacy className="flex justify-center" onClick={onClose}>
        {children}
      </WrapperLegacy>
    </Layer>
  );
}

export default WrapperDialog;
