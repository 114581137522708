import { useRef } from "react";
import { createPortal } from "react-dom";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function Layer({ id = "root", className = null, onClick = null, children }) {
  const layerRef = useRef(null);

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "fixed overflow-y-scroll top-0 bottom-0 left-0 right-0 z-layer",
    className,
  });

  const onMouseUpHandler = (e) => {
    e.stopPropagation();
    if (e.target === layerRef.current && onClick) onClick(e);
  };

  const onKeyUpHandler = (e) => e.stopPropagation();

  return createPortal(
    <div id="layer" ref={layerRef} {...classNames} onKeyUp={onKeyUpHandler} onMouseUp={onMouseUpHandler}>
      {children}
    </div>,
    document.getElementById(id)
  );
}

export default Layer;
