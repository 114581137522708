import dayjs from "dayjs";
import { LocaleContext } from "sdk/tools/providers/LocaleProvider";
import { useCallback, useContext } from "react";

function useFormatDate() {
  const { locale } = useContext(LocaleContext);

  const formatDate = useCallback(
    (value, format) => {
      if (!value) return null;
      const date = new Date(value);
      if (!dayjs(date).isValid()) return null;
      return dayjs(date).format(format ?? "");
    },
    [locale]
  );

  return formatDate;
}

export default useFormatDate;
