import { path, toPath } from "constants/Route";
import useAlertCloseConfirm from "hooks/useAlertCloseConfirm";
import { useLocation } from "react-router-dom";
import ButtonLegacyLargeSecondary from "sdk/components/Button/ButtonLegacyLargeSecondary";
import Hr from "sdk/components/Generic/Hr";
import Paragraph from "sdk/components/Paragraph/Paragraph";
import useFormatString from "sdk/tools/hooks/useFormatString";
import useInteraction from "sdk/tools/hooks/useInteraction";
import useStableNavigate from "sdk/tools/hooks/useStableNavigate";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function Navigation() {
  const { pathname } = useLocation();

  const isSignUp = pathname.includes(path.SIGNUP);

  return (
    <div className="animate-fadeIn flex flex-col mt-12 items-center gap-smallGap pb-primaryPadding">
      <Hr className="w-full max-w-[6.25rem]" />
      {!isSignUp && <SignUp />}
      {isSignUp && <SignIn />}
    </div>
  );
}

function SignUp() {
  const navigate = useStableNavigate();
  const formatString = useFormatString();

  const onClickHandler = () => navigate(toPath.publicSignUp);

  return (
    <>
      <Paragraph
        className="text-sm font-bold text-textMedium text-center"
        placeholder={formatString("registration_cta_top")}
      />
      <ButtonLegacyLargeSecondary placeholder={formatString("sign_up")} onClick={onClickHandler} />
      <Paragraph
        className="text-sm font-bold text-textMedium text-center"
        placeholder={formatString("registration_cta_bottom")}
      />
    </>
  );
}

function SignIn() {
  const formatString = useFormatString();
  const navigate = useStableNavigate();

  const navigateHandler = () => navigate(toPath.publicSignIn);
  const onCloseConfirm = useAlertCloseConfirm(navigateHandler);

  const classNames = useTwClassNames({
    className:
      "animate-fadeIn text-sm font-bold text-textMedium pointer-events-auto cursor-pointer mt-2 text-center outline-none hover:text-textWhite focus:text-accentDark",
  });
  const interactions = useInteraction({ callback: onCloseConfirm });

  return (
    <button {...classNames} {...interactions}>
      {formatString("user_registration_dismiss")}
    </button>
  );
}

export default Navigation;
