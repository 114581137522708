import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SettingDTO } from "constants/DTO";
import { userSettingKey } from "constants/Setting";
import { usersSettingsGet } from "sdk/services/endpoints";
import { LocaleContext } from "sdk/tools/providers/LocaleProvider";
import { useContext } from "react";

export const preferenceQueryKey = ["protected", "preference"];

function useQueryPreference(enabled = true) {
  const { locale, setLocale } = useContext(LocaleContext);

  const queryClient = useQueryClient();
  const initialData = queryClient.getQueryData(preferenceQueryKey);

  const queryFn = async () => {
    const data = await usersSettingsGet({ key: null, category: null });
    const preferences = {};
    data.forEach((setting) => {
      preferences[setting?.[SettingDTO.key]] = setting?.[SettingDTO.value];
    });
    const preferenceLocale = preferences?.[userSettingKey.language];
    const currentDiscreetMode = queryClient.getQueryData(preferenceQueryKey)?.[userSettingKey.discreetMode];
    if (currentDiscreetMode) preferences[userSettingKey.discreetMode] = true;
    if (preferenceLocale && preferenceLocale !== locale) setLocale(preferenceLocale);
    return preferences ?? {};
  };

  const { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess } = useQuery({
    initialData,
    enabled: enabled,
    queryKey: preferenceQueryKey,
    queryFn,
  });

  return { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess };
}

export default useQueryPreference;
