import InputLegacyLabel from "sdk/components/Input/InputLegacyLabel";
import WrapperLegacyInput from "sdk/components/Wrapper/WrapperLegacyInput";
import { forwardRef, useState } from "react";

function InputLegacy(
  {
    name = null,
    placeholder = null,
    type = null,
    disabled = false,
    defaultValue = "",
    error = false,
    customProps = null,
    onTransform = null,
    onChange = null,
    onBlur = null,
    className = null,
  },
  ref
) {
  const [value, setValue] = useState(defaultValue ?? "");
  const [labelOnTop, setLabelOnTop] = useState(!!defaultValue);

  const onFocusHandler = () => setLabelOnTop(true);

  const onChangeHandler = (e) => {
    let value = e.target.value;
    if (onTransform) value = onTransform(value);
    if (value) setLabelOnTop(!!value);
    setValue(value);
    onChange && onChange(value);
  };

  const onBlurHandler = (e) => {
    let value = e.target.value.trim();
    if (!value) setLabelOnTop(false);
    setValue(value);
    onChange && onChange(value);
    onBlur && onBlur(value);
  };

  return (
    <WrapperLegacyInput className={className}>
      <InputLegacyLabel
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        labelOnTop={labelOnTop}
      />
      <input
        ref={ref}
        disabled={disabled}
        id={name}
        name={name}
        type={type ?? "text"}
        autoComplete="off"
        maxLength={50}
        value={value}
        className="grow h-full w-full ellipsis bg-transparent input-legacy-autofill pt-4 disabled:text-textDisabledDark outline-none"
        onFocus={onFocusHandler}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        {...customProps}
      />
    </WrapperLegacyInput>
  );
}

export default forwardRef(InputLegacy);
