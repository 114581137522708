import Icon from "sdk/components/Icon/Icon";
import IconCircular from "sdk/components/Icon/IconCircular";
import useInteraction from "sdk/tools/hooks/useInteraction";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function ButtonLegacy({
  type,
  clickable = true,
  loading = false,
  disabled = false,
  invert = false,
  loaderSize = 18,
  placeholder = null,
  rightIcon = null,
  rightIconProps = null,
  onClick = null,
  className = null,
}) {
  const isClickable = !disabled && !loading && clickable;

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "relative select-none outline-none flex w-fit justify-center h-fit items-center gap-3",
    invert,
    invertClassName: "flex-row-reverse",
    clickable: isClickable,
    clickableClassName: "hover:bg-opacity-90",
    simpleIcon: !rightIcon,
    simpleClassName: "justify-center",
    disabled,
    disabledClassName: "text-textDisabledDark",
    className,
  });

  const labelClassNames = useTwClassNames({
    default: true,
    defaultClassName: "grow line-clamp-2 text-left text-inherit",
    invert,
    invertClassName: "text-right",
    simple: !rightIcon,
    simpleClassName: "text-center",
    loadingSimple: loading && !rightIcon,
    loadingSimpleClassName: "invisible",
  });

  const interactions = useInteraction({ disabled: !isClickable, callback: onClick });

  if (!Boolean(rightIcon))
    return (
      <button type={type} {...classNames} {...interactions} disabled={!isClickable}>
        <p {...labelClassNames}>{placeholder}</p>

        {loading && (
          <div className="absolute flex h-full top-0 left-0 w-full items-center justify-center">
            <IconCircular size={loaderSize} />
          </div>
        )}
      </button>
    );

  return (
    <button type={type} {...classNames} {...interactions} disabled={!isClickable}>
      <p {...labelClassNames}>{placeholder}</p>
      <div className="text-inherit flex flex-none items-center justify-center w-4 h-4 empty:hidden">
        {!loading && <Icon icon={rightIcon} {...rightIconProps} />}
        {loading && <IconCircular size={loaderSize} />}
      </div>
    </button>
  );
}

export default ButtonLegacy;
