import { useQuery } from "@tanstack/react-query";
import { CustomKeyDTO, ServiceDTO } from "constants/DTO";
import { serviceColour } from "sdk/constants/Colour";
import { servicesGet } from "sdk/services/endpoints";

export const serviceQueryKey = ["protected", "service"];

function useQueryService(enabled = true) {
  const queryFn = async () => {
    let data = await servicesGet();

    let services = [...data];
    let subServices = [];
    data.forEach((x) => {
      const item = x?.[ServiceDTO.subtypes];
      if (item?.length) subServices = [...subServices, ...item];
    });
    services = [...services, ...subServices].map((x) => ({
      ...x,
      [CustomKeyDTO.serviceColour]: serviceColour?.[x?.[ServiceDTO.shortname]],
    }));

    return services;
  };

  const { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess } = useQuery({
    enabled,
    queryKey: serviceQueryKey,
    queryFn,
  });

  return { data, error, refetch, isFetched, isFetching, isLoading, isError, isSuccess };
}

export default useQueryService;
