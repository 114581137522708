import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

function QueryProvider({ children }) {
  const devToolEnabled = (() => {
    if (process.env.NODE_ENV === "development") return true;
    return false;
  })();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {devToolEnabled && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default QueryProvider;
