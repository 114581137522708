function useLocalStorage() {
  const getItem = (item) => localStorage.getItem(item);

  const setItem = (item, value) => localStorage.setItem(item, value);

  const removeItem = (item) => localStorage.removeItem(item);

  return { getItem, setItem, removeItem };
}

export default useLocalStorage;
