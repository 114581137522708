import ButtonLegacyLargePrimary from "sdk/components/Button/ButtonLegacyLargePrimary";
import useFormatString from "sdk/tools/hooks/useFormatString";
import useMutationPending from "sdk/tools/hooks/useMutationPending";
import { authTokenGenerateMutationKey } from "services/mutations/useMutationAuthTokenGenerate";

function Action() {
  const isPending = useMutationPending({ mutationKey: authTokenGenerateMutationKey });

  const formatString = useFormatString();

  return <ButtonLegacyLargePrimary loading={isPending} className="mt-4 w-64" placeholder={formatString("login")} />;
}

export default Action;
