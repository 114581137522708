import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function WrapperLegacyInput({ className = null, children }) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "relative flex items-center h-[3.5rem] w-full grow",
    className,
  });

  return <div {...classNames}>{children}</div>;
}

export default WrapperLegacyInput;
