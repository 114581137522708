import Icon from "sdk/components/Icon/Icon";
import IconCircular from "sdk/components/Icon/IconCircular";
import useInteraction from "sdk/tools/hooks/useInteraction";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function ButtonIcon({
  clickable = true,
  loading = false,
  disabled = false,
  loaderSize = 18,
  icon = null,
  sx = null,
  onClick = null,
  className = null,
}) {
  const isClickable = !loading && !disabled && clickable;

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "w-fit h-fit outline-none text-textWhite flex items-center justify-center",
    clickable: isClickable,
    clickableClassName: "hover:opacity-80 focus:text-accentDark",
    disabled,
    disabledClassName: "!text-textDisabledDark",
    className,
  });

  const interactions = useInteraction({
    disabled: disabled || loading,
    callback: onClick,
  });

  return (
    <button type="button" {...classNames} {...interactions} disabled={loading || disabled}>
      {loading && <IconCircular size={loaderSize} />}
      {!loading && <Icon icon={icon} sx={sx} className="text-inherit" />}
    </button>
  );
}

export default ButtonIcon;
