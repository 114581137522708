import { Icons } from "sdk/components/Icon/Icons";

export const usageNotificationTypes = {
  liveEvents: "live-events",
};

export const serviceShortname = {
  le: "le",
  leMechanical: "le-mechanical",
  leLiveEvent: "le-live-event",
  leCinema: "le-cinema",
  ol: "ol",
  olStreaming: "ol-streaming",
  olDownloads: "ol-downloads",
  olNd: "ol-nd",
  mc: "mc",
  mcMechanical: "mc-mechanical",
  mcPrivateCopy: "mc-private-copy",
  rt: "rt",
  rtRadio: "rt-radio",
  rtTv: "rt-tv",
  rtNd: "rt-nd",
  bm: "bm",
  am: "am",
  bmsBackgroundMusic: "bms-background-music",
  inStoreNetwork: "in-instore-network",
  ou: "ou",
  ouNd: "ou-nd",
  ly: "ly",
  lyLyrics: "ly-lyrics",
};

export const serviceIcon = (service) => {
  if (service === serviceShortname.le) return Icons.live;
  if (service === serviceShortname.ol) return Icons.smartphone;
  if (service === serviceShortname.mc) return Icons.adjust;
  if (service === serviceShortname.rt) return Icons.radio;
  if (service === serviceShortname.am) return Icons.radio;
  if (service === serviceShortname.ou) return Icons.pieChart;
  if (service === serviceShortname.bm) return Icons.store;
  if (service === serviceShortname.ly) return Icons.queueMusic;
  return Icons.landscape;
};

export const usageNotificationIcon = (shortname) => {
  if (!shortname || shortname === usageNotificationTypes.liveEvents) return Icons.live;
};

export const serviceColour = {
  [serviceShortname.le]: "#055568",
  [serviceShortname.leMechanical]: "#055568",
  [serviceShortname.leLiveEvent]: "#028191",
  [serviceShortname.leCinema]: "#04626C",
  [serviceShortname.ol]: "#0092AA",
  [serviceShortname.olStreaming]: "#0092AA",
  [serviceShortname.olDownloads]: "#0BB7C9",
  [serviceShortname.olNd]: "#57E9ED",
  [serviceShortname.mc]: "#31BFAC",
  [serviceShortname.mcMechanical]: "#31BFAC",
  [serviceShortname.mcPrivateCopy]: "#B4FAEE",
  [serviceShortname.rt]: "#EBC867",
  [serviceShortname.rtRadio]: "#EBC867",
  [serviceShortname.rtTv]: "#FCE7B6",
  [serviceShortname.rtNd]: "#FEF1CD",
  [serviceShortname.bm]: "#FF7300",
  [serviceShortname.am]: "#FB5A05",
  [serviceShortname.inStoreNetwork]: "#FF7300",
  [serviceShortname.bmsBackgroundMusic]: "#FB5A05",
  [serviceShortname.ou]: "#F93F05",
  [serviceShortname.ouNd]: "#FF7C25",
  [serviceShortname.ly]: "#F46493",
  [serviceShortname.lyLyrics]: "#F46493",
};

const neutralGradient = ["#282828", "#FFFFFF"];
const livePerformanceGradient = ["#055568", "#04626C"];
const onlineGradient = ["#0092AA", "#0BB7C9"];
const mechanicalPrivateCopyGradient = ["#31BFAC", "#B4FAEE"];
const radioTvGradient = ["#FEF0CD", "#EBC867"];
const inStoreGradient = ["#FF7300", "#FF8828"];
const backgroundMusicGradient = ["#FB5A05", "#FF8240"];
const otherUsageGradient = ["#E07C25", "#F93F05"];
const lyricsGradient = ["#F46493", "#F993C4"];
const soundreefGradient = [
  "#09869F",
  "#0B899E",
  "#0F8D9E",
  "#14929F",
  "#1F98A0",
  "#1F98A0",
  "#6AA796",
  "#89B08F",
  "#A5BB87",
  "#C8C37F",
  "#E6C77A",
  "#F6C470",
  "#FABC64",
  "#F7AD54",
  "#F59D43",
  "#F38E36",
  "#F0802A",
  "#EE7421",
  "#EB611F",
];

function createLivePerformanceLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < livePerformanceGradient.length; i++) {
      gradient.addColorStop((1 / livePerformanceGradient.length) * i, `${livePerformanceGradient[i]}`);
    }
    return gradient;
  }
}

function createOnlineLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < onlineGradient.length; i++) {
      gradient.addColorStop((1 / onlineGradient.length) * i, `${onlineGradient[i]}`);
    }
    return gradient;
  }
}

function createMechanicalPrivateCopyLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < mechanicalPrivateCopyGradient.length; i++) {
      gradient.addColorStop((1 / mechanicalPrivateCopyGradient.length) * i, `${mechanicalPrivateCopyGradient[i]}`);
    }
    return gradient;
  }
}

export function createRadioTvLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < radioTvGradient.length; i++) {
      gradient.addColorStop((1 / radioTvGradient.length) * i, `${radioTvGradient[i]}`);
    }
    return gradient;
  }
}

function createInstoreLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < inStoreGradient.length; i++) {
      gradient.addColorStop((1 / inStoreGradient.length) * i, `${inStoreGradient[i]}`);
    }
    return gradient;
  }
}

function createBackgroundMusicLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < backgroundMusicGradient.length; i++) {
      gradient.addColorStop((1 / backgroundMusicGradient.length) * i, `${backgroundMusicGradient[i]}`);
    }
    return gradient;
  }
}

function createOtherUsageLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < otherUsageGradient.length; i++) {
      gradient.addColorStop((1 / otherUsageGradient.length) * i, `${otherUsageGradient[i]}`);
    }
    return gradient;
  }
}

function createLyricsLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < lyricsGradient.length; i++) {
      gradient.addColorStop((1 / lyricsGradient.length) * i, `${lyricsGradient[i]}`);
    }
    return gradient;
  }
}

export function createSoundreefLinearGradient(ctx, area) {
  if (ctx && area) {
    const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
    for (let i = 0; i < soundreefGradient.length; i++) {
      gradient.addColorStop((1 / soundreefGradient.length) * i, `${soundreefGradient[i]}`);
    }
    return gradient;
  }
}

export function createSoundreefObliqueGradient(ctx, area) {
  if (ctx && area) {
    const angle = -2.8 * Math.atan2(area.height, area.height) + Math.PI / 2;
    const gx = area.height * Math.cos(angle);
    const gy = area.height * Math.sin(angle);
    const cx = (0 + area.width) / 2;
    const cy = (0 + area.height) / 2;
    const gradient = ctx.createLinearGradient(cx - gx, cy - gy, cx + gx, cy + gy);
    for (let i = 0; i < soundreefGradient.length; i++) {
      gradient.addColorStop((1 / soundreefGradient.length) * i, `${soundreefGradient[i]}`);
    }
    return gradient;
  }
}

export const lineGradientServiceColourHandler = (service) => {
  let lineColourGradient = createSoundreefLinearGradient;
  switch (service) {
    case serviceShortname.le:
      lineColourGradient = createLivePerformanceLinearGradient;
      break;
    case serviceShortname.ol:
      lineColourGradient = createOnlineLinearGradient;
      break;
    case serviceShortname.mc:
      lineColourGradient = createMechanicalPrivateCopyLinearGradient;
      break;
    case serviceShortname.rt:
      lineColourGradient = createRadioTvLinearGradient;
      break;
    case serviceShortname.bm:
      lineColourGradient = createInstoreLinearGradient;
      break;
    case serviceShortname.am:
      lineColourGradient = createBackgroundMusicLinearGradient;
      break;
    case serviceShortname.ou:
      lineColourGradient = createOtherUsageLinearGradient;
      break;
    case serviceShortname.ly:
      lineColourGradient = createLyricsLinearGradient;
      break;
    default:
      break;
  }
  return lineColourGradient;
};

export const mapServiceColour = (service) => {
  let colourGradient = neutralGradient;
  switch (service) {
    case serviceShortname.le:
      colourGradient = livePerformanceGradient;
      break;
    case serviceShortname.ol:
      colourGradient = onlineGradient;
      break;
    case serviceShortname.mc:
      colourGradient = mechanicalPrivateCopyGradient;
      break;
    case serviceShortname.rt:
      colourGradient = radioTvGradient;
      break;
    case serviceShortname.bm:
      colourGradient = inStoreGradient;
      break;
    case serviceShortname.am:
      colourGradient = backgroundMusicGradient;
      break;
    case serviceShortname.ou:
      colourGradient = otherUsageGradient;
      break;
    case serviceShortname.ly:
      colourGradient = lyricsGradient;
      break;
    default:
      break;
  }
  return { minColour: colourGradient[0], maxColour: colourGradient[1] };
};
