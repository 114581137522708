import AddIcon from "@mui/icons-material/Add";
import AdjustIcon from "@mui/icons-material/Adjust";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DeleteIcon from "@mui/icons-material/Delete";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HistoryIcon from "@mui/icons-material/History";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LandscapeIcon from "@mui/icons-material/Landscape";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PauseIcon from "@mui/icons-material/Pause";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import PieChartIcon from "@mui/icons-material/PieChart";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PublicIcon from "@mui/icons-material/Public";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import RadioIcon from "@mui/icons-material/Radio";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import StarsIcon from "@mui/icons-material/Stars";
import StoreIcon from "@mui/icons-material/Store";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import TagIcon from "@mui/icons-material/Tag";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import WalletIcon from "@mui/icons-material/Wallet";
import LiveIcon from "sdk/components/Icon/LiveIcon";
import SoundreefIcon from "sdk/components/Icon/SoundreefIcon";
import HamburgerIcon from "components/components/Navbar/Component/Navigation/Component/HamburgerIcon";

export const Icons = {
  add: "add",
  adjust: "adjust",
  audioTrack: "audioTrack",
  alternateEmail: "alternateEmail",
  attachFile: "attachFile",
  arrowDownward: "arrowDownward",
  arrowForward: "arrowForward",
  arrowUpworward: "arrowUpworward",
  callMerge: "callMerge",
  check: "check",
  chevronLeft: "chevronLeft",
  chevronRight: "chevronRight",
  clearAll: "clearAll",
  close: "close",
  creditCard: "creditCard",
  delete: "delete",
  donutLarge: "donutLarge",
  edit: "edit",
  email: "email",
  eventBusy: "eventBusy",
  euroSymbol: "euroSymbol",
  exitToApp: "exitToApp",
  fileDownload: "fileDownload",
  fingerPrint: "fingerPrint",
  hamburger: "hamburger",
  helpOutline: "helpOutline",
  history: "history",
  keyboardArrowDown: "keyboardArrowDown",
  insertDriveFile: "insertDriveFile",
  landscape: "landscape",
  live: "live",
  moveToInbox: "moveToInbox",
  notifications: "notifications",
  pause: "pause",
  payment: "payment",
  pieChart: "pieChart",
  person: "person",
  peopleAlt: "peopleAlt",
  play: "play",
  public: "public",
  queueMusic: "queueMusic",
  radio: "radio",
  receipt: "receipt",
  removeRedEye: "removeRedEye",
  search: "search",
  settigs: "settigs",
  smartphone: "smartphone",
  soundreef: "soundreef",
  stars: "stars",
  store: "store",
  surround: "surround",
  tag: "tag",
  turnedInNot: "turnedInNot",
  visibilityOff: "visibilityOff",
  vpnKeyIcon: "vpnKeyIcon",
  uploadFile: "uploadFile",
  wallet: "wallet",
};

export const IconsMaterial = {
  [Icons.add]: <AddIcon />,
  [Icons.adjust]: <AdjustIcon />,
  [Icons.alternateEmail]: <AlternateEmailIcon />,
  [Icons.attachFile]: <AttachFileIcon />,
  [Icons.arrowDownward]: <ArrowDownwardIcon />,
  [Icons.arrowForward]: <ArrowForwardIcon />,
  [Icons.arrowUpworward]: <ArrowUpwardIcon />,
  [Icons.audioTrack]: <AudiotrackIcon />,
  [Icons.callMerge]: <CallMergeIcon />,
  [Icons.check]: <CheckIcon />,
  [Icons.chevronLeft]: <ChevronLeftIcon />,
  [Icons.chevronRight]: <ChevronRightIcon />,
  [Icons.clearAll]: <ClearAllIcon />,
  [Icons.close]: <CloseIcon />,
  [Icons.creditCard]: <CreditCardIcon />,
  [Icons.delete]: <DeleteIcon />,
  [Icons.donutLarge]: <DonutLargeIcon />,
  [Icons.edit]: <EditIcon />,
  [Icons.email]: <EmailIcon />,
  [Icons.eventBusy]: <EventBusyIcon />,
  [Icons.euroSymbol]: <EuroSymbolIcon />,
  [Icons.exitToApp]: <ExitToAppIcon />,
  [Icons.fileDownload]: <FileDownloadIcon />,
  [Icons.fingerPrint]: <FingerprintIcon />,
  [Icons.hamburger]: <HamburgerIcon />,
  [Icons.helpOutline]: <HelpOutlineIcon />,
  [Icons.history]: <HistoryIcon />,
  [Icons.keyboardArrowDown]: <KeyboardArrowDownIcon />,
  [Icons.insertDriveFile]: <InsertDriveFileIcon />,
  [Icons.landscape]: <LandscapeIcon />,
  [Icons.live]: <LiveIcon />,
  [Icons.moveToInbox]: <MoveToInboxIcon />,
  [Icons.notifications]: <NotificationsIcon />,
  [Icons.pause]: <PauseIcon />,
  [Icons.peopleAlt]: <PeopleAltIcon />,
  [Icons.payment]: <PaymentIcon />,
  [Icons.person]: <PersonIcon />,
  [Icons.pieChart]: <PieChartIcon />,
  [Icons.play]: <PlayArrowIcon />,
  [Icons.queueMusic]: <QueueMusicIcon />,
  [Icons.search]: <SearchIcon />,
  [Icons.public]: <PublicIcon />,
  [Icons.radio]: <RadioIcon />,
  [Icons.receipt]: <ReceiptIcon />,
  [Icons.removeRedEye]: <RemoveRedEyeIcon />,
  [Icons.settigs]: <SettingsIcon />,
  [Icons.smartphone]: <SmartphoneIcon />,
  [Icons.soundreef]: <SoundreefIcon />,
  [Icons.stars]: <StarsIcon />,
  [Icons.store]: <StoreIcon />,
  [Icons.surround]: <SurroundSoundIcon />,
  [Icons.tag]: <TagIcon />,
  [Icons.turnedInNot]: <TurnedInNotIcon />,
  [Icons.visibilityOff]: <VisibilityOffIcon />,
  [Icons.vpnKeyIcon]: <VpnKeyIcon />,
  [Icons.uploadFile]: <UploadFileIcon />,
  [Icons.wallet]: <WalletIcon />,
};
