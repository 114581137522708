import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { documentFilterType } from "constants/Document";
import { ReduxAction } from "sdk/tools/constants/Redux";

const filterDateSlice = createSlice({
  name: "document/filterDate",
  initialState: null,
  reducers: ReduxAction,
});

const filterSongSlice = createSlice({
  name: "document/filterSong",
  initialState: "",
  reducers: ReduxAction,
});

const filterTypeSlice = createSlice({
  name: "document/filterType",
  initialState: documentFilterType.all_categories,
  reducers: ReduxAction,
});

const filterTabSlice = createSlice({
  name: "document/filterTab",
  initialState: 0,
  reducers: ReduxAction,
});

export const filterTypeActions = filterTypeSlice.actions;
export const filterSongActions = filterSongSlice.actions;
export const filterDateActions = filterDateSlice.actions;
export const filterTabActions = filterTabSlice.actions;

export const filterTypeSelector = createSelector(
  (state) => state,
  (state) => state.document.filterType
);

export const filterSongSelector = createSelector(
  (state) => state,
  (state) => state.document.filterSong
);

export const filterDateSelector = createSelector(
  (state) => state,
  (state) => state.document.filterDate
);

export const filterTabSelector = createSelector(
  (state) => state,
  (state) => state.document.filterTab
);

const documentReducer = combineReducers({
  filterSong: filterSongSlice.reducer,
  filterType: filterTypeSlice.reducer,
  filterDate: filterDateSlice.reducer,
  filterTab: filterTabSlice.reducer,
});

export default documentReducer;
