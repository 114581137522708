import Action from "app/Route/Public/Route/SignIn/Component/Action";
import DiscreetMode from "app/Route/Public/Route/SignIn/Component/DiscreetMode";
import Email from "app/Route/Public/Route/SignIn/Component/Email";
import Password from "app/Route/Public/Route/SignIn/Component/Password";
import Recover from "app/Route/Public/Route/SignIn/Component/Recover";
import SignInForm from "app/Route/Public/Route/SignIn/SignInForm";

function SignIn() {
  return (
    <SignInLayout>
      <SignInForm>
        <Email />
        <Password />
        <DiscreetMode />
        <Action />
        <Recover />
      </SignInForm>
    </SignInLayout>
  );
}

function SignInLayout({ children }) {
  return (
    <div className="animate-fadeIn mx-auto lg:mt-smallMargin grid w-full max-w-[31.25rem] grid-cols-1 gap-smallGap">
      {children}
    </div>
  );
}

export default SignIn;
