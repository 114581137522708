import CardLegacy from "sdk/components/Card/CardLegacy";
import ContainerColumn from "sdk/components/Container/ContainerColumn";
import DialogSmall from "sdk/components/Dialog/DialogSmall";
import HeaderDialog from "sdk/components/Header/HeaderDialog";
import Paragraph from "sdk/components/Paragraph/Paragraph";
import { ShowContext } from "sdk/tools/components/Show";
import useFormatLocale from "sdk/tools/hooks/useFormatLocale";
import useFormatString from "sdk/tools/hooks/useFormatString";
import { LocaleContext } from "sdk/tools/providers/LocaleProvider";
import useMutationPreferenceLocale from "services/mutations/useMutationPreferenceLocale";
import { useContext } from "react";

function Locale() {
  const { close } = useContext(ShowContext);

  const formatString = useFormatString();

  return (
    <DialogSmall onClose={close}>
      <HeaderDialog placeholder={formatString("language")} onClose={close} />
      <List />
    </DialogSmall>
  );
}

function List() {
  const { locale, availableLocales } = useContext(LocaleContext);
  const { close } = useContext(ShowContext);

  const formatLocale = useFormatLocale();

  const { onChange } = useMutationPreferenceLocale();

  const onChangeHandler = (locale) => {
    onChange(locale);
    close();
  };

  return (
    <ContainerColumn className="gap-2">
      {availableLocales.map((item, index) => (
        <CardLegacy className="py-2" key={index} clickable={item !== locale} onClick={() => onChangeHandler(item)}>
          {item !== locale && <Paragraph placeholder={formatLocale(item)} />}
          {item === locale && <Paragraph className="text-accentPrimary" placeholder={formatLocale(item)} />}
        </CardLegacy>
      ))}
    </ContainerColumn>
  );
}

export default Locale;
