import IconCircular from "sdk/components/Icon/IconCircular";
import WrapperDialog from "sdk/components/Wrapper/WrapperDialog";
import EventCode from "sdk/tools/constants/EventCode";
import useKeyUp from "sdk/tools/hooks/useKeyUp";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import FocusLock from "react-focus-lock";

function DialogSmall({ loading = false, onClose = null, className = null, children }) {
  useKeyUp(EventCode.escape, onClose);

  const classNames = useTwClassNames({
    default: true,
    defaultClassName:
      "relative animate-fadeBottom outline-none focus:border-white rounded-xl border-2 border-fillDark bg-fillAppBackground px-5 py-4 lg:mx-0 sm:w-full md:w-[28rem]",
    loading,
    loadingClassName: "min-h-[15rem] flex items-center text-accentPrimary justify-center",
    className,
  });

  return (
    <WrapperDialog onClose={onClose}>
      <FocusLock autoFocus={false} {...classNames}>
        {loading && <IconCircular className="mx-auto" size={28} />}
        {!loading && children}
      </FocusLock>
    </WrapperDialog>
  );
}

export default DialogSmall;
