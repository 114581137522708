import useLocalStorageToken from "hooks/useLocalStorageToken";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadScreen from "sdk/components/Loader/LoadScreen";
import useQueryRemove from "sdk/tools/hooks/useQueryRemove";
import { cleanReducer } from "store/store";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [isReady, setIsReady] = useState(false);
  const [authorized, setAuthorized] = useState(null);

  const { getToken, setToken, removeToken } = useLocalStorageToken();

  const dispatch = useDispatch();
  const remove = useQueryRemove();

  const onAuth = (token) => {
    setToken(token);
    setAuthorized(true);
  };

  const onAuthRevoke = () => {
    remove({ queryKey: ["protected"] });
    dispatch(cleanReducer());
    removeToken();
    setAuthorized(false);
  };

  useEffect(() => {
    const token = getToken();
    setAuthorized(!!token);
    setTimeout(() => setIsReady(true), 800);
  }, []);

  if (!isReady) return <LoadScreen progress={0} />;
  return <AuthContext.Provider value={{ authorized, onAuth, onAuthRevoke }}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
