import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function Hr({ className = null }) {
  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "border-fillMedium",
    className: className,
  });

  return <hr {...classNames} />;
}

export default Hr;
