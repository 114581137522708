import { authRealm } from "constants/Auth";
import WrapperForm from "sdk/components/Wrapper/WrapperForm";
import { useToastError } from "hooks/useToast";
import { AuthContext } from "components/providers/AuthProvider";
import useMutationAuthTokenGenerate from "services/mutations/useMutationAuthTokenGenerate";
import { useContext } from "react";

function SignInForm({ children }) {
  const { onAuth } = useContext(AuthContext);

  const setError = useToastError();

  const { mutateAsync } = useMutationAuthTokenGenerate();

  const onSubmit = async (payload) => {
    try {
      const token = await mutateAsync({
        email: payload?.email,
        password: payload?.password,
        realm: authRealm.rightHolder,
      });
      onAuth(token);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <WrapperForm onSubmit={onSubmit} className="flex flex-col gap-smallGap items-center">
      {children}
    </WrapperForm>
  );
}

export default SignInForm;
