import { AuthContext } from "components/providers/AuthProvider";
import { useContext } from "react";
import Guard from "sdk/tools/components/Guard";

function PublicGuard({ shouldHide = false, navigateTo = null, children }) {
  const { authorized } = useContext(AuthContext);

  return (
    <Guard guardIf={authorized} shouldHide={shouldHide} navigateTo={navigateTo}>
      {children}
    </Guard>
  );
}

export default PublicGuard;
