import IconCircular from "sdk/components/Icon/IconCircular";
import SoundreefLogoVertical from "components/components/Logo/SoundreefLogoVertical";

function LoadScreen({ progress = 0 }) {
  return (
    <div className="fixed z-[9998] bg-fillAppBackground left-0 top-0 flex h-screen w-screen pb-[9.375rem] flex-col items-center justify-center text-white">
      <div className="h-44">
        <SoundreefLogoVertical className="h-full w-full object-contain" />
      </div>
      <IconCircular progress={progress} thickness={6} size={40} />
    </div>
  );
}

export default LoadScreen;
