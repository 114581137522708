import { createContext, useRef } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";

export const StableNavigateContext = createContext({});

function RouteProvider({ children }) {
  return (
    <BrowserRouter>
      <StableNavigateProvider>{children}</StableNavigateProvider>
    </BrowserRouter>
  );
}

function StableNavigateProvider({ children }) {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  return <StableNavigateContext.Provider value={navigateRef}>{children}</StableNavigateContext.Provider>;
}

export default RouteProvider;
