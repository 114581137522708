import { CircularProgress } from "@mui/material";

function IconCircular({ sx = null, size = null, thickness = 6, progress = null, className = null }) {
  return (
    <CircularProgress
      variant={progress === null ? "indeterminate" : "determinate"}
      className={className}
      value={progress}
      thickness={thickness}
      size={size}
      sx={{ display: "flex", color: "inherit", ...sx }}
    />
  );
}

export default IconCircular;
