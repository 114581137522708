import axios from "axios";
import { CustomKeyDTO } from "constants/DTO";

export const version = "1.1";

// Default api options without interceptor for authentication
export const defaultApiOptions = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// api for multipart/form-data upload
export const multipartAuthApiOptions = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// Default api options with interceptor for authentication
export const defaultAuthApiOptions = { ...defaultApiOptions };

// Auth request interceptors
defaultAuthApiOptions.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem([CustomKeyDTO.token])}`,
  };
  return config;
});

multipartAuthApiOptions.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem([CustomKeyDTO.token])}`,
  };
  return config;
});
