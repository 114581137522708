import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { sortByDirection } from "constants/Library";
import { monitoringDefaultDateRange, monitoringSortBy } from "constants/Monitoring";
import { ReduxAction } from "sdk/tools/constants/Redux";

const activePageSlice = createSlice({
  name: "monitoring/activePage",
  initialState: null,
  reducers: ReduxAction,
});

const filterChannelSlice = createSlice({
  name: "monitoring/filterChannel",
  initialState: null,
  reducers: ReduxAction,
});

const filterCountrySlice = createSlice({
  name: "monitoring/filterCountry",
  initialState: null,
  reducers: ReduxAction,
});

const filterDateSlice = createSlice({
  name: "monitoring/filterDate",
  initialState: monitoringDefaultDateRange,
  reducers: ReduxAction,
});

const filterMediaTypeSlice = createSlice({
  name: "monitoring/filterMediaType",
  initialState: 0,
  reducers: ReduxAction,
});

const filterSearchSlice = createSlice({
  name: "monitoring/filterSearch",
  initialState: null,
  reducers: ReduxAction,
});

const filterSearchTypeSlice = createSlice({
  name: "monitoring/filterSearchType",
  initialState: 0,
  reducers: ReduxAction,
});

const groupBySlice = createSlice({
  name: "monitoring/groupBy",
  initialState: null,
  reducers: ReduxAction,
});

const sortBySlice = createSlice({
  name: "monitoring/sortBy",
  initialState: `${sortByDirection.descendant}${monitoringSortBy.date}`,
  reducers: ReduxAction,
});

export const activePageActions = activePageSlice.actions;
export const filterChannelActions = filterChannelSlice.actions;
export const filterCountryActions = filterCountrySlice.actions;
export const filterDateActions = filterDateSlice.actions;
export const filterMediaTypeActions = filterMediaTypeSlice.actions;
export const filterSearchActions = filterSearchSlice.actions;
export const filterSearchTypeActions = filterSearchTypeSlice.actions;
export const groupByActions = groupBySlice.actions;
export const sortByActions = sortBySlice.actions;

export const activePageSelector = createSelector(
  (state) => state,
  (state) => state.monitoring.activePage
);

export const filterChannelSelector = createSelector(
  (state) => state,
  (state) => state.monitoring.filterChannel
);

export const filterCountrySelector = createSelector(
  (state) => state,
  (state) => state.monitoring.filterCountry
);

export const filterDateSelector = createSelector(
  (state) => state,
  (state) => state.monitoring.filterDate
);

export const filterMediaTypeSelector = createSelector(
  (state) => state,
  (state) => state.monitoring.filterMediaType
);

export const filterSearchSelector = createSelector(
  (state) => state,
  (state) => state.monitoring.filterSearch
);

export const filterSearchTypeSelector = createSelector(
  (state) => state,
  (state) => state.monitoring.filterSearchType
);

export const groupBySelector = createSelector(
  (state) => state,
  (state) => state.monitoring.groupBy
);

export const sortBySelector = createSelector(
  (state) => state,
  (state) => state.monitoring.sortBy
);

const monitoringReducer = combineReducers({
  activePage: activePageSlice.reducer,
  groupBy: groupBySlice.reducer,
  sortBy: sortBySlice.reducer,
  filterSearch: filterSearchSlice.reducer,
  filterSearchType: filterSearchTypeSlice.reducer,
  filterDate: filterDateSlice.reducer,
  filterCountry: filterCountrySlice.reducer,
  filterChannel: filterChannelSlice.reducer,
  filterMediaType: filterMediaTypeSlice.reducer,
});

export default monitoringReducer;
