import { linkEnvironmentAppend } from "constants/Link";
import useQueryGetData from "sdk/tools/hooks/useQueryGetData";
import { LocaleContext } from "sdk/tools/providers/LocaleProvider";
import { settingUrlQueryKey } from "services/queries/useQueryUrl";
import { useContext } from "react";

function useHrefFromKey(settingKey) {
  const { locale } = useContext(LocaleContext);

  const urls = useQueryGetData({ queryKey: settingUrlQueryKey });

  const href = (() => {
    const environmentKey = linkEnvironmentAppend + settingKey;
    const currentSetting = urls?.find((url) => url?.settingKey === environmentKey);
    let value = currentSetting?.value;
    if (value && value.includes("{locale}")) {
      value = value.replace("{locale}", locale);
    }
    return value ? value : "";
  })();

  return { href };
}

export default useHrefFromKey;
