import { StableNavigateContext } from "sdk/tools/providers/RouteProvider";
import { useContext } from "react";

function useStableNavigate() {
  const navigateRef = useContext(StableNavigateContext);
  const navigate = navigateRef.current;

  return navigate;
}

export default useStableNavigate;
