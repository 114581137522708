import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { ReduxAction } from "sdk/tools/constants/Redux";

const filterSearchSlice = createSlice({
  name: "registration/filterSearch",
  initialState: null,
  reducers: ReduxAction,
});

const filterSearchTypeSlice = createSlice({
  name: "registration/filterSearchType",
  initialState: 0,
  reducers: ReduxAction,
});

const filterDateSlice = createSlice({
  name: "registration/filterDate",
  initialState: null,
  reducers: ReduxAction,
});

const filterRightHolderSlice = createSlice({
  name: "registration/filterRightHolder",
  initialState: "",
  reducers: ReduxAction,
});

const filterStatusSlice = createSlice({
  name: "registration/filterStatus",
  initialState: 0,
  reducers: ReduxAction,
});

export const filterSearchActions = filterSearchSlice.actions;
export const filterSearchTypeActions = filterSearchTypeSlice.actions;
export const filterDateActions = filterDateSlice.actions;
export const filterRightHolderActions = filterRightHolderSlice.actions;
export const filterStatusActions = filterStatusSlice.actions;

export const filterSearchSelector = createSelector(
  (state) => state,
  (state) => state.registration.filterSearch
);

export const filterSearchTypeSelector = createSelector(
  (state) => state,
  (state) => state.registration.filterSearchType
);

export const filterDateSelector = createSelector(
  (state) => state,
  (state) => state.registration.filterDate
);

export const filterRightHolderSelector = createSelector(
  (state) => state,
  (state) => state.registration.filterRightHolder
);

export const filterStatusSelector = createSelector(
  (state) => state,
  (state) => state.registration.filterStatus
);

const registrationReducer = combineReducers({
  filterSearch: filterSearchSlice.reducer,
  filterSearchType: filterSearchTypeSlice.reducer,
  filterDate: filterDateSlice.reducer,
  filterRightHolder: filterRightHolderSlice.reducer,
  filterStatus: filterStatusSlice.reducer,
});

export default registrationReducer;
