import { linkKey } from "constants/Link";
import ButtonParagraph from "sdk/components/Button/ButtonParagraph";
import Href from "sdk/components/Generic/Href";
import Paragraph from "sdk/components/Paragraph/Paragraph";
import useHrefFromKey from "hooks/useHrefFromKey";
import { memo, useContext, useRef } from "react";
import Show from "sdk/tools/components/Show";
import useFormatLocale from "sdk/tools/hooks/useFormatLocale";
import useFormatString from "sdk/tools/hooks/useFormatString";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import { LocaleContext } from "sdk/tools/providers/LocaleProvider";
import Locale from "components/dialogs/Locale/Locale";

function Footer({ className }) {
  const { locale } = useContext(LocaleContext);

  const formatString = useFormatString();
  const formatLocale = useFormatLocale();

  const showRef = useRef(null);

  const { href: privacyPolicy } = useHrefFromKey(linkKey.url_privacy_policy);
  const { href: terms } = useHrefFromKey(linkKey.url_terms);

  const classNames = useTwClassNames({
    default: true,
    defaultClassName:
      "sm:flex-col sm:items-center lg:flex-row gap-smallGap justify-center flex mt-auto w-full py-primaryPadding",
    className: className,
  });

  return (
    <footer {...classNames}>
      <Href className="font-light text-sm" href={privacyPolicy} placeholder={formatString("privacy_policy")} />
      <Href className="font-light text-sm" href={terms} placeholder={formatString("terms_of_service")} />
      <Show ref={showRef} component={<Locale />}>
        <ButtonParagraph
          className="text-textMedium font-light text-sm hover:text-textWhite"
          placeholder={formatLocale(locale)}
          onClick={() => showRef.current.open()}
        />
      </Show>
      <Paragraph
        className="font-light sm:text-center text-sm text-textMedium group-[.is-public]:w-full group-[.is-public]:text-left"
        placeholder={formatString("user_support_footer")}
      />
    </footer>
  );
}

export default memo(Footer);
