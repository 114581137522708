import Guard from "sdk/tools/components/Guard";

function DeveloperGuard({ shouldHide = false, navigateTo = null, children }) {
  const isDevelopment = (() => {
    if (process.env.NODE_ENV === "development") return true;
    return false;
  })();

  return (
    <Guard guardIf={!isDevelopment} shouldHide={shouldHide} navigateTo={navigateTo}>
      {children}
    </Guard>
  );
}

export default DeveloperGuard;
