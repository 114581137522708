import { useRef } from "react";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function WrapperLegacy({ className = null, onClick = null, children }) {
  const wrapperRef = useRef(null);

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "relative mx-auto w-full max-w-[90rem] sm:px-primaryPadding md:px-smallPadding 2xl:px-0",
    className,
  });

  const onMouseUpHandler = (e) => {
    if (e.target === wrapperRef.current && onClick) onClick(e);
  };

  return (
    <div ref={wrapperRef} {...classNames} onMouseUp={onMouseUpHandler}>
      {children}
    </div>
  );
}

export default WrapperLegacy;
