import { userSettingKey } from "constants/Setting";
import Paragraph from "sdk/components/Paragraph/Paragraph";
import useQueryPreference from "services/queries/useQueryPreference";
import useFormatString from "sdk/tools/hooks/useFormatString";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";

function DiscreetModeParagraph({ className }) {
  const formatString = useFormatString();

  const { data: preference } = useQueryPreference(false);

  const discreetMode = preference?.[userSettingKey.discreetMode];

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "text-sm inline",
    className: className,
  });

  const placeholder = (() => {
    if (discreetMode) return formatString("discreet_mode_on");
    if (!discreetMode) return formatString("discreet_mode_off");
  })();

  return <Paragraph {...classNames} placeholder={placeholder} />;
}

export default DiscreetModeParagraph;
