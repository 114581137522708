import DiscreetModeButton from "components/components/DiscreetMode/DiscreetModeButton";
import DiscreetModeParagraph from "components/components/DiscreetMode/DiscreetModeParagraph";

function DiscreetMode() {
  return (
    <div className="flex items-center gap-smallGap self-end font-bold text-textMedium">
      <DiscreetModeParagraph />
      <DiscreetModeButton className="text-2xl" />
    </div>
  );
}

export default DiscreetMode;
