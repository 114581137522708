import Dayjs from "dayjs";

export const monitoringIntervalUnit = {
  day: "day",
  week: "week",
  month: "month",
};

export const monitoringMediaTypes = {
  radio: "radio",
  tv: "tv",
};

export const monitoringGroupBy = {
  date: "date",
  broadcastChannel: "broadcastChannel",
  song: "song",
  country: "country",
};

export const monitoringSortBy = {
  date: "date",
};

export const monitoringDateIntervalGet = (startDate, endDate) => {
  if (!startDate || !endDate) return monitoringIntervalUnit.week;
  if (Dayjs(endDate).diff(startDate, monitoringIntervalUnit.day) < 31) return monitoringIntervalUnit.day;
  if (Dayjs(endDate).diff(startDate, monitoringIntervalUnit.day) > 90) return monitoringIntervalUnit.month;
  return monitoringIntervalUnit.week;
};

export const monitoringDefaultDateRange = {
  startDate: Dayjs().subtract(15, "day").format("YYYY-MM-DD").toString(),
  endDate: Dayjs().subtract(1, "day").format("YYYY-MM-DD").toString(),
};
