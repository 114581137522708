import ErrorTooltip from "sdk/components/Tooltip/ErrorTooltip";
import useFlatObject from "sdk/tools/hooks/useFlatObject";
import useFormatString from "sdk/tools/hooks/useFormatString";
import useSanitizeString from "sdk/tools/hooks/useSanitizeString";
import useTwClassNames from "sdk/tools/hooks/useTwClassNames";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

function WrapperField({
  name = null,
  required = false,
  validate = null,
  errorHelper = true,
  onChange = null,
  onBlur = null,
  className = null,
  children,
}) {
  const formatString = useFormatString();
  const flatObject = useFlatObject();
  const sanitizeString = useSanitizeString();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const classNames = useTwClassNames({
    default: true,
    defaultClassName: "w-full h-fit",
    className,
  });

  const defaultValue = useMemo(() => {
    return flatObject(getValues())?.[name] ?? null;
  }, []);
  const errorMessage = flatObject(errors)?.[`${name}.message`];
  const tooltip = errorHelper && formatString(errorMessage);

  return (
    <ErrorTooltip title={tooltip}>
      <div {...classNames}>
        <Controller
          name={name ?? ""}
          control={control}
          rules={{
            required: required ? "field_required" : false,
            validate: {
              sanitize: (e) => {
                if (typeof e === "string" && e && e !== sanitizeString(e)) return "field_generic_error";
                return true;
              },
              ...validate,
            },
            onChange: (value) => {
              if (onChange) onChange(value);
              return {
                target: {
                  name: name,
                  value: value ?? null,
                },
              };
            },
            onBlur: (value) => {
              if (onBlur) onBlur(value);
              return {
                target: {
                  name: name,
                  value: value ?? null,
                },
              };
            },
          }}
          render={({ field }) =>
            React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                name: name,
                defaultValue: defaultValue,
                error: !!errorMessage,
                ...field,
              });
            })
          }
        />
      </div>
    </ErrorTooltip>
  );
}

export default WrapperField;
