import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { usageNotificationTypes } from "sdk/constants/Colour";
import { ReduxAction } from "sdk/tools/constants/Redux";

const filterTabSlice = createSlice({
  name: "usageNotification/filterTab",
  initialState: usageNotificationTypes.liveEvents,
  reducers: ReduxAction,
});

export const filterTabActions = filterTabSlice.actions;

export const filterTabSelector = createSelector(
  (state) => state,
  (state) => state.usageNotification.filterTab
);

const usageNotificationReducer = combineReducers({
  filterTab: filterTabSlice.reducer,
});

export default usageNotificationReducer;
