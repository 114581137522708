const EventCode = {
  enter: "Enter",
  escape: "Escape",
  tab: "Tab",
  digit0: "Digit0",
  digit1: "Digit1",
  digit2: "Digit2",
  digit3: "Digit3",
  digit4: "Digit4",
  digit5: "Digit5",
  digit6: "Digit6",
  digit7: "Digit7",
  digit8: "Digit8",
  digit9: "Digit9",
  keyE: "KeyE",
  period: "Period",
  comma: "Comma",
  plus: "BracketRight",
  slash: "Slash",
  space: "Space",
};

export default EventCode;
