import ButtonShowHide from "sdk/components/Button/ButtonShowHide";
import ContainerCard from "sdk/components/Input/ContainerCard";
import InputLegacy from "sdk/components/Input/InputLegacy";
import { forwardRef, useState } from "react";

function InputLegacyPasswordCard(
  {
    name = null,
    placeholder = null,
    disabled = false,
    defaultValue = "",
    error = false,
    customProps = null,
    onBlur = null,
    onChange = null,
    className = null,
  },
  ref
) {
  const [isHidden, setIsHidden] = useState(true);

  const type = isHidden ? "password" : "password-new";

  return (
    <ContainerCard error={error} disabled={disabled} className={className}>
      <InputLegacy
        ref={ref}
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
        customProps={customProps}
        onChange={onChange}
        onBlur={onBlur}
      />
      <ButtonShowHide hidden={isHidden} disabled={disabled} onChange={setIsHidden} />
    </ContainerCard>
  );
}

export default forwardRef(InputLegacyPasswordCard);
