import { path } from "constants/Route";
import { Icons } from "sdk/components/Icon/Icons";
import { useUserHasMonitoringEnabled, useUserIsValid } from "hooks/useUser";
import { createContext, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

export const NavigationContext = createContext({});

export function NavigationProvider({ children }) {
  const { pathname } = useLocation();

  const activePage = pathname.split("/").filter((x) => !!x)[1];
  const previousActivePageRef = useRef(activePage);

  const isHome = (() => {
    if (previousActivePageRef.current === path.HOME && activePage === path.SONG && pathname?.includes(path.BATCH))
      return true;
    if (previousActivePageRef.current === path.HOME && activePage === path.USAGE_NOTIFICATION) return true;
    previousActivePageRef.current = activePage;
    return activePage === path.HOME;
  })();

  const isValid = useUserIsValid();
  const hasMonitoringEnabled = useUserHasMonitoringEnabled();

  const navigation = useMemo(() => {
    let array = [
      {
        id: path.HOME,
        path: path.HOME,
        placeholder: "dashboard_tab_home",
        icon: Icons.soundreef,
        iconProps: { sx: { fontSize: 16 } },
      },
      {
        id: path.PAYMENT,
        path: path.PAYMENT,
        placeholder: "dashboard_tab_payments",
        icon: Icons.payment,
        iconProps: { sx: { fontSize: 18 } },
      },
      {
        id: path.ROYALTY,
        path: path.ROYALTY,
        placeholder: "dashboard_tab_royalty",
        icon: Icons.euroSymbol,
        iconProps: { sx: { fontSize: 18 } },
      },
      {
        id: path.MONITORING,
        path: path.MONITORING,
        placeholder: "dashboard_tab_monitoring",
        icon: Icons.radio,
        iconProps: { sx: { fontSize: 18 } },
      },
      {
        id: path.SONG,
        path: path.SONG,
        placeholder: "dashboard_tab_music",
        icon: Icons.audioTrack,
        iconProps: { sx: { fontSize: 18 } },
      },
    ];
    if (!isValid) array = array.filter((x) => x?.id === path.HOME);
    if (!hasMonitoringEnabled) array = array.filter((x) => x?.id !== path.MONITORING);
    return array;
  }, [isValid, hasMonitoringEnabled]);

  return <NavigationContext.Provider value={{ navigation, activePage, isHome }}>{children}</NavigationContext.Provider>;
}
