import App from "app/App";
import DOMPurify from "dompurify";
import "firebaseConfig";
import ReactDOM from "react-dom/client";
import "styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
DOMPurify(window);

root.render(<App />);
