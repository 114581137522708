import { externalLink } from "constants/Link";
import Paragraph from "sdk/components/Paragraph/Paragraph";
import SoundreefLogoHorizontal from "components/components/Logo/SoundreefLogoHorizontal";
import StoreAppleLogo from "components/components/Logo/StoreAppleLogo";
import StoreGooglePlayLogo from "components/components/Logo/StoreGooglePlayLogo";
import useFormatString from "sdk/tools/hooks/useFormatString";
import Footer from "components/components/Footer/Footer";

function Placeholder() {
  const formatString = useFormatString();

  const onClickHandler = () => window.location.assign(externalLink.soundreef);

  return (
    <div className="h-full relative z-10 flex-col p-layoutPadding flex">
      <div className="h-7 w-fit">
        <SoundreefLogoHorizontal active={true} className="h-full" onClick={onClickHandler} />
      </div>
      <div className="animate-fadeIn short:hidden tall:block pt-36">
        <h2 className="text-[4.875rem] max-w-[30rem] font-bold leading-[4.25rem]">
          {formatString("login_claim_title")}
        </h2>
        <h3 className="mt-2 text-xl font-medium">{formatString("login_claim_subtitle")}</h3>
      </div>
      <div className="w-full mb-5 grow flex flex-col justify-end gap-smallGap">
        <Paragraph placeholder={formatString("available_for_mobile_devices")} />
        <div className="flex gap-smallGap">
          <StoreAppleLogo />
          <StoreGooglePlayLogo />
        </div>
      </div>
      <Footer className="flex flex-row flex-wrap justify-start gap-smallGap items-end mt-smallMargin group is-public" />
    </div>
  );
}

export default Placeholder;
