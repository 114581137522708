import { loginBgImage } from "constants/Cdn";
import { toPath } from "constants/Route";
import Image from "sdk/components/Generic/Image";
import useStableNavigate from "sdk/tools/hooks/useStableNavigate";

function Background() {
  const navigate = useStableNavigate();

  const onClickHandler = () => navigate(toPath.publicSignIn);

  return (
    <div className="absolute left-0 top-0 z-0 h-screen w-screen flex">
      <div className="relative h-full w-full">
        <div className="absolute left-0 top-0 z-10 h-full w-full" />
        <Image
          className="relative z-0 h-full w-full bg-cover object-cover object-right"
          src={loginBgImage}
          opacity={0.3}
          alt="Soundreef Background"
          onClick={onClickHandler}
        />
      </div>
      <div className="w-full bg-fillAppBackground" />
    </div>
  );
}

export default Background;
