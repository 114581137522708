import dayjs from "dayjs";
import { useCallback } from "react";

function useDateByYear() {
  const dateByYear = useCallback((year) => {
    if (typeof year !== "number") return null;
    return dayjs().set("year", dayjs().get("year") + year);
  }, []);

  return dateByYear;
}

export default useDateByYear;
