import InputLegacyPasswordCard from "sdk/components/Input/InputLegacyPasswordCard";
import WrapperField from "sdk/components/Wrapper/WrapperField";
import useFormatString from "sdk/tools/hooks/useFormatString";

function Password() {
  const formatString = useFormatString();

  return (
    <WrapperField name="password" required={true}>
      <InputLegacyPasswordCard placeholder={formatString("password")} />
    </WrapperField>
  );
}

export default Password;
