export const ReduxAction = {
  set: (state, action) => action.payload,
  reset: (state, action) => null,
  mergeObj: (state, action) => ({ ...state, ...action.payload }),
  mergeArr: (state, action) => [...state, ...action.payload],
  pushArr: (state, action) => [...state, action.payload],
  filterArr: (state, action) => state.filter((x) => x !== action.payload),
  mergePag: (state, action) => {
    const prevData = state.data;
    const nextData = action.payload.data;
    return {
      ...state,
      ...action.payload,
      data: [...prevData, ...nextData],
    };
  },
};
