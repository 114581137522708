import DOMPurify from "dompurify";
import { useCallback } from "react";

function useSanitizeString() {
  const sanitizeString = useCallback((value) => {
    const string = DOMPurify.sanitize(value, {
      ALLOWED_TAGS: [],
    })
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
    return string;
  }, []);

  return sanitizeString;
}

export default useSanitizeString;
